import React, { useEffect, useCallback, useState, useMemo } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { CSS } from '@dnd-kit/utilities'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import {
  fetchAllContent,
  fetchFullUserContent,
  updateUserContent,
  sortTopicsArray,
} from '@edwin/sdk-admin'
import { AButton } from '../atoms'
import { MAssignTopicListItem } from '../molecules'

import { OAssignTopicWithMissions, OLoader } from '../organisms'

import { MGridItem } from '../molecules/MGridItem'
import { MTableRowWithSelect } from '../molecules/MTableRowWithSelect'

const checkIfSomeTopicMissionsAreCompleted = topic => {
  return topic?.missionsAndSeries?.some(tms => tms?.progress?.isCompleted)
}

const SortableAssignedTopic = ({
  id,
  viewOnly,
  topic,
  index,
  onToggleMission,
  isDragDisabled = false,
  isExpanded = true,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: id,
    disabled: isDragDisabled,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <MAssignTopicListItem
      ref={setNodeRef}
      style={style}
      index={index}
      {...attributes}
      {...listeners}
      topic={topic}
      viewOnly={viewOnly}
      onToggleMission={onToggleMission}
      isExpanded={isExpanded}
      isDragDisabled={isDragDisabled}
    />
  )
}

export const OAssignContentByUser = ({
  user,
  isExpanded = true,
  viewOnly = false,
  ctaReorderLabel = 'Reorder categories',
  ctaCancelReorderLabel = 'Cancel reorder',
  className,
}) => {
  const [isUserContentLoading, setIsUserContentLoading] = useState(true)
  const [localAssignedTopics, setLocalAssignedTopics] = useState([])
  const [notAssignedTopic, setNotAssignedTopics] = useState([])
  const [assignedTopics, setAssignedTopics] = useState([])
  const [fullContent, setFullContent] = useState([])
  const [isFullContentLoading, setIsFullContentLoading] = useState(false)
  const [assignedOnboardingMission, setAssignedOnboardingMission] = useState()

  const [localAssignedOnboardingMission, setLocalAssignedOnboardingMission] = useState()

  const [isSaving, setIsSaving] = useState(false)
  const [isOnboardingSaving, setIsOnboardingSaving] = useState(false)
  const [areTopicsSaving, setAreTopicsSaving] = useState(false)

  const [isTopicDraggingAllowed, setIsTopicDraggingAllowed] = useState(!isExpanded)
  const [draggedTopic, setDraggedTopic] = useState(false)

  const handleFetchContent = useCallback(async () => {
    setIsFullContentLoading(true)
    const content = await fetchAllContent()
    const userContent = await fetchFullUserContent(user.id)

    setAssignedOnboardingMission(userContent.onboardingMission)
    setLocalAssignedOnboardingMission(userContent.onboardingMission)

    setFullContent(content)
    setAssignedTopics(
      userContent.topics
        .filter(userTopic => content?.topics?.find(t => t.id === userTopic.id))
        .map(userTopic => {
          const topicMissions =
            content?.topics?.find(t => t.id === userTopic.id)?.missionsAndSeries || []

          const userTopicMissions = topicMissions.map(tms => {
            const userTopicMission = userTopic.missionsAndSeries?.find(ums => ums.id === tms.id)

            const isAssigned = !!userTopicMission

            const mission = isAssigned ? { ...tms, ...userTopicMission } : tms

            return {
              ...mission,
              isAssigned,
            }
          })

          return {
            ...userTopic,
            missionsAndSeries: userTopicMissions,
          }
        })
    )

    setIsFullContentLoading(false)
  }, [user?.id])

  const handleSaveUserContent = useCallback(
    async data => {
      const { topics, onboardingMission } = data

      await updateUserContent(user?.id, topics, onboardingMission?.id || null)
      await handleFetchContent()
    },
    [handleFetchContent, user?.id]
  )

  useEffect(() => {
    const asyncFn = async () => {
      setIsUserContentLoading(true)
      await handleFetchContent()
      setIsUserContentLoading(false)
    }

    asyncFn()
  }, [handleFetchContent])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragStart = useCallback(
    event => {
      const { active } = event

      if (active) {
        const currentlyDraggedTopic = localAssignedTopics.find(item => item.id === active.id)

        setDraggedTopic(currentlyDraggedTopic)
      }
    },
    [localAssignedTopics]
  )

  const handleDragEnd = useCallback(
    event => {
      const { active, over } = event

      const isAnyTopicMissionCompleted = checkIfSomeTopicMissionsAreCompleted(draggedTopic)

      // Do not allow dragging if any topic mission is completed
      if (isAnyTopicMissionCompleted) {
        return
      }

      if (active.id !== over.id) {
        setLocalAssignedTopics(items => {
          const oldIndex = items.findIndex(item => item.id === active.id)
          const newIndex = items.findIndex(item => item.id === over.id)

          return arrayMove(items, oldIndex, newIndex)
        })
        setDraggedTopic(false)
      }
    },
    [draggedTopic]
  )

  const isSaveChangesDisabled = useMemo(() => {
    const isTopicsEqual = _.isEqual(
      localAssignedTopics.map(topic => ({
        ...topic,
        missionsAndSeries: topic.missionsAndSeries?.filter(tms => !tms.isDisabled),
      })),
      assignedTopics
    )

    const isOnboardingMissionEqual = _.isEqual(
      localAssignedOnboardingMission,
      assignedOnboardingMission
    )

    if (isTopicsEqual && isOnboardingMissionEqual) {
      return true
    }

    return false
  }, [
    assignedOnboardingMission,
    assignedTopics,
    localAssignedOnboardingMission,
    localAssignedTopics,
  ])

  const isLocalOnboardingMissionChanged = useMemo(() => {
    const isOnboardingMissionEqual = _.isEqual(
      localAssignedOnboardingMission,
      assignedOnboardingMission
    )

    return !isOnboardingMissionEqual
  }, [assignedOnboardingMission, localAssignedOnboardingMission])

  const areTopicsChanged = useMemo(() => {
    const isTopicsEqual = _.isEqual(
      localAssignedTopics.map(topic => ({
        ...topic,
        missionsAndSeries: topic.missionsAndSeries?.filter(tms => !tms.isDisabled),
      })),
      assignedTopics
    )

    return !isTopicsEqual
  }, [assignedTopics, localAssignedTopics])

  const handleToggleMission = useCallback(
    (topicId, tmsId) => {
      const remoteTopic = localAssignedTopics?.find(localTopic => localTopic.id === topicId) || {}
      const remoteMission = remoteTopic.missionsAndSeries?.find(tms => tms.id === tmsId) || {}

      if (viewOnly || remoteTopic?.progress?.isCompleted || remoteMission?.progress?.isCompleted) {
        return
      }

      setLocalAssignedTopics(localAssignedTopics => {
        return localAssignedTopics.map(topic => {
          const topicObj = { ...topic }

          if (topic.id === topicId) {
            return {
              ...topicObj,
              missionsAndSeries: topicObj.missionsAndSeries.map(tms => {
                let tmsObj = { ...tms }

                if (tmsObj.id === tmsId) {
                  tmsObj.isAssigned = !tmsObj.isAssigned
                }

                return tmsObj
              }),
            }
          }

          return topicObj
        })
      })
    },
    [localAssignedTopics, viewOnly]
  )

  const handleAllMissionsAssigned = useCallback(
    (topicId, value) => {
      const remoteTopic = localAssignedTopics?.find(localTopic => localTopic.id === topicId) || {}

      if (viewOnly || remoteTopic?.progress?.isCompleted) {
        return
      }

      setLocalAssignedTopics(localAssignedTopics => {
        return localAssignedTopics.map(topic => {
          const topicObj = { ...topic }

          if (topic.id === topicId) {
            return {
              ...topicObj,
              missionsAndSeries: topicObj.missionsAndSeries.map(tms => {
                let tmsObj = { ...tms }

                if (!tmsObj?.progress?.isCompleted) {
                  tmsObj.isAssigned = value
                }

                return tmsObj
              }),
            }
          }

          return topicObj
        })
      })
    },
    [localAssignedTopics, viewOnly]
  )

  const handleAddTopic = useCallback(
    topicId => {
      setLocalAssignedTopics(prevState => {
        const array = [...prevState]
        const addedTopic = fullContent.topics.find(topic => topic.id === topicId)

        if (addedTopic) {
          array.push(addedTopic)
        }

        return array
      })
    },
    [fullContent]
  )

  const handleDeleteTopic = useCallback(
    topicId => {
      const remoteTopic =
        fullContent?.topics?.find(allContentTopic => allContentTopic.id === topicId) || {}

      if (checkIfSomeTopicMissionsAreCompleted(remoteTopic)) return

      setLocalAssignedTopics(prevState => {
        const array = [...prevState].filter(topic => topic.id !== topicId)

        return array
      })
    },
    [fullContent?.topics]
  )

  const handleAddOnboardingMission = useCallback(
    missionId => {
      const missionToAssign = fullContent?.onboardingMissions.find(
        mission => mission.id === missionId
      )

      if (missionToAssign) {
        setLocalAssignedOnboardingMission(missionToAssign)
      }
    },
    [fullContent?.onboardingMissions]
  )

  const handleSaveAssignment = useCallback(async () => {
    setIsSaving(true)

    const parsedTopics = localAssignedTopics
      .map(topic => {
        return {
          id: topic.id,
          missionsAndSeries: topic.missionsAndSeries.map(tms => {
            return {
              id: tms.id,
              type: tms.type,
              isAssigned: tms.isAssigned !== false,
            }
          }),
        }
      })
      .filter(topic => {
        if (!topic?.missionsAndSeries.length) {
          return false
        }

        return true
      })

    const onboardingMission = { ...localAssignedOnboardingMission }

    await handleSaveUserContent({ topics: parsedTopics, onboardingMission })

    if (isExpanded) {
      //if custom save and isExpandedf is passed reset to initial expanded state
      setIsTopicDraggingAllowed(!isExpanded)
    }

    setIsSaving(false)

    return { topics: parsedTopics, onboardingMission }
  }, [localAssignedTopics, localAssignedOnboardingMission, handleSaveUserContent, isExpanded])

  const handleSaveOnboardingMission = useCallback(async () => {
    setIsSaving(true)
    setIsOnboardingSaving(true)

    const onboardingMission = { ...localAssignedOnboardingMission }

    await handleSaveUserContent({ topics: assignedTopics, onboardingMission })

    setIsSaving(false)
    setIsOnboardingSaving(false)

    return { onboardingMission }
  }, [localAssignedOnboardingMission, handleSaveUserContent, assignedTopics])

  const handleSaveTopics = useCallback(async () => {
    setIsSaving(true)
    setAreTopicsSaving(true)

    const parsedTopics = localAssignedTopics
      .map(topic => {
        return {
          id: topic.id,
          missionsAndSeries: topic.missionsAndSeries.map(tms => {
            return {
              id: tms.id,
              type: tms.type,
              isAssigned: tms.isAssigned !== false,
            }
          }),
        }
      })
      .filter(topic => {
        if (!topic?.missionsAndSeries.length) {
          return false
        }

        return true
      })

    await handleSaveUserContent({
      topics: parsedTopics,
      onboardingMission: assignedOnboardingMission,
    })

    if (isExpanded) {
      //if custom save and isExpandedf is passed reset to initial expanded state
      setIsTopicDraggingAllowed(!isExpanded)
    }

    setIsSaving(false)
    setAreTopicsSaving(false)

    return { topics: parsedTopics }
  }, [localAssignedTopics, handleSaveUserContent, assignedOnboardingMission, isExpanded])

  useEffect(() => {
    if (assignedTopics && !isSaving) {
      setLocalAssignedTopics(assignedTopics)
    }
  }, [assignedTopics, fullContent?.topics, isSaving, viewOnly])

  useEffect(() => {
    const allTopics = fullContent?.topics

    if (allTopics) {
      setNotAssignedTopics(
        allTopics
          .filter(topic => {
            return !localAssignedTopics.find(assignedTopic => assignedTopic.id === topic.id)
          })
          .sort(sortTopicsArray)
      )
    }
  }, [localAssignedTopics, fullContent?.topics])

  if (isFullContentLoading) {
    return (
      <div className="react-web--admin__container" style={{ height: '100%' }}>
        <div className="flex items-center h-full">
          <OLoader className="h-full" />
        </div>
      </div>
    )
  }

  return (
    <div className="react-web--admin__container">
      {!!fullContent?.onboardingMissions?.length && (
        <div className="mb-8 lg:mb-14">
          <div className="md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-2xl font-bold leading-9 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                Available onboarding missions
              </h2>
            </div>
            {!viewOnly && isLocalOnboardingMissionChanged && (
              <div className="inline-block my-2 md:my-0 md:ml-4">
                <AButton
                  className="ml-2 w-full"
                  isLoading={isOnboardingSaving}
                  onClick={handleSaveOnboardingMission}
                  loadingText="Saving assignment..."
                >
                  Save changes
                </AButton>
              </div>
            )}
          </div>

          <div className="mt-2 lg:mt-6 ring-1 ring-gray-300 rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <tbody>
                {fullContent?.onboardingMissions?.map((onboardingMission, index) => (
                  <MTableRowWithSelect
                    key={onboardingMission.id}
                    title={onboardingMission.title || onboardingMission.name}
                    activeItem={localAssignedOnboardingMission?.id === onboardingMission.id}
                    firstRow={index === 0}
                    onClick={() => handleAddOnboardingMission(onboardingMission.id)}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-semibold leading-9 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
            Assigned categories ({localAssignedTopics.length || 0})
          </h2>
        </div>
        {!viewOnly && (localAssignedTopics.length > 1 || areTopicsChanged) && (
          <div className="my-2 md:my-0 flex md:ml-4 md:mt-0">
            {localAssignedTopics.length > 1 && (
              <button
                type="button"
                onClick={() => {
                  setIsTopicDraggingAllowed(prev => !prev)
                }}
                className="whitespace-nowrap inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {isTopicDraggingAllowed ? ctaCancelReorderLabel : ctaReorderLabel}
              </button>
            )}
            {areTopicsChanged && (
              <AButton
                className={classnames('md:w-full', { 'ml-2': localAssignedTopics.length > 1 })}
                isLoading={areTopicsSaving}
                onClick={handleSaveTopics}
                loadingText="Saving assignment..."
              >
                Save changes
              </AButton>
            )}
          </div>
        )}
      </div>

      {!!localAssignedTopics.length ? (
        <div className="mt-2 lg:mt-6">
          {isTopicDraggingAllowed && (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext items={localAssignedTopics} strategy={verticalListSortingStrategy}>
                <ul role="list" className="divide-y divide-gray-100">
                  {localAssignedTopics.map((topic, index) => {
                    return (
                      <li key={topic.id}>
                        <SortableAssignedTopic
                          key={topic.id}
                          id={topic.id}
                          topic={topic}
                          index={index}
                          viewOnly={viewOnly}
                          onToggleMission={handleToggleMission}
                          isDragDisabled={!isTopicDraggingAllowed}
                          isExpanded={!isTopicDraggingAllowed}
                          onDeleteTopic={handleDeleteTopic}
                        />
                      </li>
                    )
                  })}
                </ul>
              </SortableContext>
            </DndContext>
          )}

          {!isTopicDraggingAllowed && (
            <div className="grid gap-y-6">
              {localAssignedTopics.map((topic, index) => {
                return (
                  <OAssignTopicWithMissions
                    key={topic.id}
                    index={index}
                    topic={topic}
                    missions={topic?.missionsAndSeries}
                    assignedTopics={localAssignedTopics}
                    onDeleteTopic={handleDeleteTopic}
                    onToggleMission={handleToggleMission}
                    onAllMissionsChange={handleAllMissionsAssigned}
                    viewOnly={viewOnly}
                  />
                )
              })}
            </div>
          )}
        </div>
      ) : (
        <span className="inline-block mt-2 text-gray-500 font-light">No topics assigned</span>
      )}

      {!!notAssignedTopic?.length && !viewOnly && (
        <div className="mt-8 lg:mt-14">
          <h2 className="text-2xl font-bold leading-9 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Available topics
          </h2>

          <ul className="mt-2 lg:mt-6 rounded-lg shadow overflow-hidden">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 -m-px">
              {notAssignedTopic?.map(notAssignedTopic => (
                <li key={notAssignedTopic.id} className="col-span-1 border-[0.5px] border-gray-200">
                  <MGridItem
                    title={notAssignedTopic.title || notAssignedTopic.name}
                    description={notAssignedTopic.description}
                    numberOfAssignments={`${
                      notAssignedTopic.missionsAndSeries?.length || 0
                    } assignments`}
                    onClick={() => handleAddTopic(notAssignedTopic.id)}
                  />
                </li>
              ))}
            </div>
          </ul>
        </div>
      )}
    </div>
  )
}

OAssignContentByUser.propTypes = {
  assignedTopics: PropTypes.array,
  assignedOnboardingMission: PropTypes.object,
  allContentToAssign: PropTypes.object,
  onSave: PropTypes.func,
  viewOnly: PropTypes.bool,
  ctaReorderLabel: PropTypes.string,
  ctaCancelReorderLabel: PropTypes.string,
  children: PropTypes.func,
}
