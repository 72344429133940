import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'

import { MFormInput } from '../molecules/MFormInput.js'
import { AButton } from '../atoms/AButton.js'

export const OOrganizationOverview = ({
  organization,
  updateOrganization,
  isSuperAdmin,
  isLoading,
}) => {
  const [isEditable, setIsEditable] = useState(false)
  const [isEditSaving, setIsEditSaving] = useState(false)
  const [editError, setEditError] = useState()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...organization },
  })

  const handleEditButton = useCallback(() => {
    setIsEditable(!isEditable)
  }, [isEditable])

  const handleSubmitEdit = async data => {
    setEditError(null)
    setIsEditSaving(true)
    try {
      await updateOrganization(data)
      setIsEditable(false)
    } catch (err) {
      setEditError(err?.message)
    }

    setIsEditSaving(false)
  }

  return (
    <div className="react-web--admin__container">
      <div className="pt-12 pb-24 px-6 sm:px-6 lg:px-8">
        <form onSubmit={handleSubmit(handleSubmitEdit)}>
          <div className="overflow-hidden">
            <div className="flex flex-row items-center">
              {isSuperAdmin && (
                <div className="flex flex-row items-center">
                  <AButton onClick={handleEditButton} secondary>
                    {!isEditable ? 'Edit' : 'Cancel Editting'}
                  </AButton>
                </div>
              )}
            </div>
            <div className="py-5">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>

                  {isEditable ? (
                    <MFormInput
                      id="name"
                      type="text"
                      name="name"
                      errorMessage={errors?.name?.message}
                      {...register('name', {
                        required: {
                          value: true,
                          message: 'This field is required',
                        },
                      })}
                    />
                  ) : (
                    <dd className="mt-1 text-sm text-gray-900">{organization?.name}</dd>
                  )}
                </div>
              </dl>
            </div>

            {isEditable && (
              <div className="flex flex-row justify-end w-full p-4">
                <div className="flex flex-row items-center">
                  {!!editError && (
                    <span className="text-sm leading-5 font-medium rounded-md text-red-500 mr-4">
                      {editError}
                    </span>
                  )}
                  <AButton type="submit" isLoading={isEditSaving}>
                    Save changes
                  </AButton>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
