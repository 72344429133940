import React from 'react'
import classnames from 'classnames'

import PropTypes from 'prop-types'

export const MTableRowWithSelect = ({ title, activeItem, firstRow, viewOnly, onClick }) => (
  <tr>
    <td
      className={classnames(
        firstRow ? '' : 'border-t border-transparent',
        'relative w-full py-4 pl-4 pr-3 text-sm sm:pl-6'
      )}
    >
      <div className={classnames('text-gray-900', activeItem ? 'font-bold' : 'font-medium')}>
        {title}
      </div>
      {!firstRow ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" /> : null}
    </td>
    <td
      className={classnames(
        firstRow ? '' : 'border-t border-transparent',
        'relative inline-block py-4 pl-4 pr-3 text-sm font-medium sm:pr-6'
      )}
    >
      {!viewOnly && (
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
          disabled={activeItem}
          onClick={onClick}
        >
          {activeItem ? 'Selected' : 'Select'}
          <span className="sr-only">, {title}</span>
        </button>
      )}
      {!firstRow ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" /> : null}
    </td>
  </tr>
)

MTableRowWithSelect.propTypes = {
  title: PropTypes.string,
  activeItem: PropTypes.bool,
  firstRow: PropTypes.bool,
  viewOnly: PropTypes.bool,
  onClick: PropTypes.func,
}
