import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { AFormCheckboxItem } from '../atoms/AFormCheckboxItem.js'
import { AFormInputWrapper } from '../atoms/AFormInputWrapper.js'

export const MFormOrganizationsCheckboxList = ({
  organizations,
  aclValues,
  multipleSelection = true,
  errorMessage,
  onChange = () => {},
  className,
}) => {
  return (
    <div className="react-web--admin__container">
      <AFormInputWrapper errorMessage={errorMessage}>
        <div
          className={classnames(
            'border-b border-gray-200 divide-y divide-gray-200 mt-2 pr-1 max-h-96 overflow-y-auto',
            {
              [className]: true,
            }
          )}
        >
          <>
            {organizations.map((organization, index) => {
              const isChecked = aclValues?.[organization.id] || false

              return (
                <AFormCheckboxItem
                  key={organization.id}
                  id={organization.id}
                  name={organization.name}
                  checked={isChecked}
                  className="py-4"
                  onChange={event => {
                    let currentValue = aclValues || {}

                    if (multipleSelection) {
                      const isSelected = !currentValue[organization.id]

                      let changedValue = {
                        ...currentValue,
                      }

                      if (!isSelected && changedValue[organization.id]) {
                        delete changedValue[organization.id]
                      }

                      if (isSelected) {
                        changedValue[organization.id] = true
                      }

                      if (!Object.keys(changedValue).length) {
                        changedValue = null
                      }

                      onChange(changedValue)
                    } else {
                      onChange({ [organization.id]: true })
                    }
                  }}
                />
              )
            })}
          </>
        </div>
      </AFormInputWrapper>
    </div>
  )
}

MFormOrganizationsCheckboxList.propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
  ).isRequired,
  aclValues: PropTypes.object,
  multipleSelection: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
}
