import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { MSearchInput } from '../molecules/MSearchInput.js'
import { MEmptyContentPlaceholder } from '../molecules/MEmptyContentPlaceholder'

export const OAdminUsersList = ({ users, onItemClick, className }) => {
  const [visibleUsers, setVisibleUsers] = useState(users)

  useEffect(() => {
    if (!visibleUsers && users.length) {
      setVisibleUsers(users)
    }
  }, [users, visibleUsers])

  const handleSearch = useCallback(searchUsers => {
    setVisibleUsers(searchUsers)
  }, [])

  return (
    <div className="react-web--admin__container">
      {!!users?.length && (
        <MSearchInput
          items={users}
          onSearch={handleSearch}
          searchByKeys={['firstName', 'lastName', 'email']}
          className="mb-6"
        />
      )}

      {!!visibleUsers?.length ? (
        <div className="ring-1 ring-gray-300 rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell uppercase"
                >
                  Email
                </th>

                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell uppercase"
                >
                  Role
                </th>

                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell uppercase"
                >
                  Organizations
                </th>

                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell uppercase"
                >
                  Teams
                </th>
              </tr>
            </thead>
            <tbody>
              {visibleUsers.map((user, index) => (
                <tr key={user.email} className="cursor-pointer" onClick={() => onItemClick(user)}>
                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-transparent',
                      'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
                    )}
                  >
                    <div className="font-medium text-gray-900">
                      {user.firstName} {user.lastName}
                    </div>
                    <div className="mt-1 flex flex-col text-gray-500 lg:hidden">
                      <span>{user.email}</span>
                      <span className="sm:hidden">{user.role?.label}</span>
                    </div>
                    {index !== 0 ? (
                      <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                    ) : null}
                  </td>
                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                    {user.email}
                  </td>

                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 sm:table-cell'
                    )}
                  >
                    {user.role?.label}
                  </td>

                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                    {user.isSuperAdmin ? 'All' : user.organizations?.length}
                  </td>

                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                    {user.role?.key === 'super_admin' || user.role?.key === 'admin'
                      ? 'All'
                      : user.teams?.length}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mt-12">
          <MEmptyContentPlaceholder>No admin users assigned</MEmptyContentPlaceholder>
        </div>
      )}
    </div>
  )
}

OAdminUsersList.propTypes = {
  users: PropTypes.array,
  onItemClick: PropTypes.func,
  className: PropTypes.string,
}
