import classnames from 'classnames'
import PropTypes from 'prop-types'

export const ABadge = ({ status = 'Completed', className }) => {
  const statuses = {
    Completed: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  }

  return (
    <span
      className={classnames(
        className,
        statuses[status],
        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
      )}
    >
      {status}
    </span>
  )
}

ABadge.propTypes = {
  status: PropTypes.oneOf(['Completed', 'In progress']),
}
