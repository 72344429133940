import React from 'react'
import classnames from 'classnames'
import { Rings } from 'react-loader-spinner'

export const AButton = ({
  children,
  onClick,
  className,
  secondary,
  warning,
  isLoading,
  disabled,
  loadingText = 'Working...',
  loadingIcon = <Rings type="Rings" color="white" height={18} width={18} />,
  ...props
}) => {
  return (
    <div className="react-web--admin__container">
      <button
        type="button"
        className={classnames(
          'block rounded-md font-semibold px-3 py-2 text-center text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
          !secondary &&
            !warning &&
            'text-white shadow-sm bg-accent hover:bg-opacity-90 focus-visible:outline-accent',
          secondary && 'leading-5 text-gray-900 bg-transparent border border-raven shadow-sm',
          warning &&
            'text-white shadow-sm bg-red-600 hover:bg-red-500 focus-visible:outline-red-600',
          className,
          {
            'opacity-50 cursor-not-allowed': isLoading || disabled,
          }
        )}
        disabled={isLoading || disabled}
        onClick={onClick}
        {...props}
      >
        {isLoading ? (
          <div className="flex justify-center items-center w-full">
            {Boolean(loadingIcon) && <div className="mr-1">{loadingIcon}</div>}
            {Boolean(loadingText) && <span>{loadingText}</span>}
          </div>
        ) : (
          children
        )}
      </button>
    </div>
  )
}
