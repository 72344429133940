import React from 'react'
import { useNavigate } from 'react-router-dom'

import OPageWrapper from '@organisms/OPageWrapper'
import { AButton, OAdminUsersList } from '@edwin/react-web-admin'
import { useAdminUsers } from '@services/users'

import ROUTES, { generateRoute } from '@const/Routes'

const PAdminUsers = () => {
  const navigate = useNavigate()
  const [adminUsers] = useAdminUsers()

  const breadcrumbs = [{ type: 'home', navigateTo: ROUTES.ADMIN_USERS }]

  return (
    <OPageWrapper
      breadcrumbs={breadcrumbs}
      buttonElement={
        <AButton
          onClick={() => {
            navigate(generateRoute(ROUTES.ADD_ADMIN_USER))
          }}
        >
          Add admin user
        </AButton>
      }
    >
      <OAdminUsersList
        users={adminUsers}
        onItemClick={adminUser => {
          navigate(generateRoute(ROUTES.ADMIN_USER, { adminUserId: adminUser.id }), {
            state: { adminUser },
          })
        }}
      />
    </OPageWrapper>
  )
}

export default PAdminUsers
