import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export const MEmptyContentPlaceholder = ({
  description = 'No content assigned',
  children,
  className,
}) => {
  return (
    <div className="react-web--admin__container h-full">
      <div
        className={classnames(
          'flex flex-grow flex-auto w-full h-full flex-col items-center justify-center text-lg font-semibold',
          {
            [className]: !!className,
          }
        )}
      >
        {children || description}
      </div>
    </div>
  )
}

MEmptyContentPlaceholder.propTypes = {
  description: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}
