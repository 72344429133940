import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'

import { MFormInput } from '../molecules/MFormInput.js'
import { AButton } from '../atoms/AButton.js'

export const OEditOrganization = ({
  organization,
  onSavingFinished = () => {},
  isLoading,
  onUpdateOrganization = () => {},
}) => {
  const [isEditSaving, setIsEditSaving] = useState(false)
  const [editError, setEditError] = useState()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...organization },
  })

  const handleSubmitEdit = async data => {
    setEditError(null)
    setIsEditSaving(true)
    try {
      await onUpdateOrganization(data)
      await onSavingFinished()
    } catch (err) {
      setEditError(err?.message)
    }

    setIsEditSaving(false)
  }

  return (
    <div className="react-web--admin__container">
      <form onSubmit={handleSubmit(handleSubmitEdit)}>
        <div className="grid max-w-2xl grid-cols-1 gap-y-4">
          <div>
            <MFormInput
              id="name"
              type="text"
              name="name"
              label="Name"
              errorMessage={errors?.name?.message}
              {...register('name', {
                required: {
                  value: true,
                  message: 'This field is required',
                },
              })}
            />
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <div className="flex flex-col items-center">
            <AButton type="submit" className="w-full" isLoading={isEditSaving}>
              Save changes
            </AButton>

            {!!editError && (
              <div className="text-sm leading-5 mt-2 font-medium text-red-500">{editError}</div>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

OEditOrganization.propTypes = {
  organization: PropTypes.object.isRequired,
  onSavingFinished: PropTypes.func,
  isLoading: PropTypes.bool,
}
