import React, { useCallback, useState, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export const MTabs = ({ tabs, activeTab, onTabChange = () => {}, className }) => {
  const [currentTab, setCurrentTab] = useState(tabs[0])
  const [tabsArray, setTabsArray] = useState(tabs)

  useEffect(() => {
    setTabsArray(tabs)
  }, [tabs])

  useEffect(() => {
    setTabsArray(prevTabs =>
      prevTabs.map(tab => ({
        ...tab,
        isActive: tab.name === currentTab.name,
      }))
    )
  }, [currentTab])

  useEffect(() => {
    if (activeTab) {
      setCurrentTab(tabs.find(tab => tab.id === activeTab))
    }
  }, [activeTab, tabs])

  const handleTabChange = useCallback(
    tab => {
      setCurrentTab(tab)

      onTabChange(tab)
    },
    [onTabChange]
  )

  return (
    <div className="react-web--admin__container">
      <div className={classnames({ [className]: !!className })}>
        <div className="lg:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            onChange={e => {
              const tabName = e.target.value
              const tab = tabsArray.find(tab => tab.name === tabName)

              handleTabChange(tab)
            }}
            className="block w-full rounded-md border-gray-300 focus:border-accent focus:ring-accent"
            defaultValue={tabs.find(tab => tab.id === currentTab?.id)?.name || tabs[0].name}
          >
            {tabsArray.map(tab => (
              <option key={tab.id}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden lg:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabsArray.map(tab => (
                <div
                  key={tab.id}
                  onClick={() => handleTabChange(tab)}
                  className={classnames(
                    'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium',
                    tab.id === currentTab?.id
                      ? 'border-accent text-accent'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer'
                  )}
                >
                  <tab.icon
                    className={classnames(
                      '-ml-0.5 mr-2 h-5 w-5',
                      tab.id === currentTab?.id
                        ? 'text-accent'
                        : 'text-gray-400 group-hover:text-gray-500'
                    )}
                    // aria-hidden="true"
                  />
                  <span>{tab.name}</span>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

MTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, component: PropTypes.node })
  ),
  onTabChange: PropTypes.func,
  className: PropTypes.string,
}
