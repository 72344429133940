import React, { useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

/*global CometChatWidget*/ // To disable any eslint 'CometChatWidget not defined' errors
export const OChatWidget = ({ chatCredentials, onItemClick = () => {}, onLogout = () => {} }) => {
  const isChatInitialized = useRef(false)
  const isChatInitializing = useRef(false)

  const handleInitializeChatWidget = useCallback(async () => {
    if (chatCredentials && !isChatInitialized.current && !isChatInitializing.current) {
      isChatInitializing.current = true

      await CometChatWidget.init({
        appID: chatCredentials.appId,
        appRegion: chatCredentials.region,
        authKey: chatCredentials.authKey,
      })

      await CometChatWidget.login({
        uid: chatCredentials.uid,
      })

      if (!isChatInitialized.current) {
        await CometChatWidget.launch({
          widgetID: chatCredentials.widgetId,
          target: '#cometchat',
          defaultType: 'user', //user or group
        }).then(() => {
          CometChatWidget.on('onItemClick', args => {
            onItemClick()
          })
        })
      }

      isChatInitialized.current = true
      isChatInitializing.current = false
    }
  }, [chatCredentials, onItemClick])

  const handleCloseChatWidget = useCallback(async () => {
    if (!isChatInitialized.current || isChatInitializing.current) {
      return
    }

    onLogout()

    await CometChatWidget.logout()
  }, [onLogout])

  useEffect(() => {
    handleInitializeChatWidget()
  }, [handleInitializeChatWidget])

  useEffect(() => {
    return () => {
      handleCloseChatWidget()
    }
  }, [handleCloseChatWidget])

  return (
    <div className="flex items-center justify-center w-full h-full" style={{ zIndex: 1 }}>
      <div id="cometchat" className="h-full flex-auto w-full"></div>
    </div>
  )
}

OChatWidget.propTypes = {
  chatCredentials: PropTypes.shape({
    appId: PropTypes.string.isRequired,
    authKey: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    widgetId: PropTypes.string.isRequired,
  }),
  onItemClick: PropTypes.func,
  onLogout: PropTypes.func,
}
