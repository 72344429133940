import axios from 'axios'

let axiosInstance = null

export const createAxiosInstance = ({ config, adminTokenLocalStorageKey }) => {
  if (axiosInstance) return axiosInstance

  axiosInstance = axios.create({
    baseURL: config.edwinCloudApi,
    headers: {
      'x-api-key': localStorage.getItem(adminTokenLocalStorageKey),
    },
  })

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    function (config) {
      config.headers['x-api-key'] = localStorage.getItem(adminTokenLocalStorageKey)

      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error)
    }
  )

  return axiosInstance
}

export const getAxiosInstance = () => {
  return axiosInstance
}
