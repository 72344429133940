import React, { useCallback, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export const MSearchInput = ({
  className,
  items,
  onSearch,
  placeholder = 'Search',
  searchByKeys = ['email'],
  numberOfCharsToSearch = 3,
}) => {
  const [searchValue, setSearchValue] = useState('')

  const handleSearchChange = useCallback(
    e => {
      const { value } = e.target

      setSearchValue(value)

      if (value?.length >= numberOfCharsToSearch) {
        const filteredItems = items.filter(item => {
          const val = value.toLowerCase()

          const isValid = searchByKeys.some(key => {
            const searchValue = item[key]?.toLowerCase() || ''

            return searchValue.includes(val)
          })

          return isValid
        })

        onSearch(filteredItems)
      }

      if (value.length === 0) {
        onSearch(items)
      }
    },
    [items, onSearch, searchByKeys, numberOfCharsToSearch]
  )

  return (
    <div className="react-web--admin__container">
      <div className="relative">
        <div className="absolute h-full flex flex-row items-center ml-6">
          <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoCiAgICAgICAgZD0iTTE5IDE5TDEzIDEzTTE1IDhDMTUgOC45MTkyNSAxNC44MTg5IDkuODI5NTEgMTQuNDY3MiAxMC42Nzg4QzE0LjExNTQgMTEuNTI4MSAxMy41OTk4IDEyLjI5OTcgMTIuOTQ5NyAxMi45NDk3QzEyLjI5OTcgMTMuNTk5OCAxMS41MjgxIDE0LjExNTQgMTAuNjc4OCAxNC40NjcyQzkuODI5NTEgMTQuODE4OSA4LjkxOTI1IDE1IDggMTVDNy4wODA3NSAxNSA2LjE3MDUgMTQuODE4OSA1LjMyMTIyIDE0LjQ2NzJDNC40NzE5NCAxNC4xMTU0IDMuNzAwMjYgMTMuNTk5OCAzLjA1MDI1IDEyLjk0OTdDMi40MDAyNCAxMi4yOTk3IDEuODg0NjMgMTEuNTI4MSAxLjUzMjg0IDEwLjY3ODhDMS4xODEwNiA5LjgyOTUxIDEgOC45MTkyNSAxIDhDMSA2LjE0MzQ4IDEuNzM3NSA0LjM2MzAxIDMuMDUwMjUgMy4wNTAyNUM0LjM2MzAxIDEuNzM3NSA2LjE0MzQ4IDEgOCAxQzkuODU2NTIgMSAxMS42MzcgMS43Mzc1IDEyLjk0OTcgMy4wNTAyNUMxNC4yNjI1IDQuMzYzMDEgMTUgNi4xNDM0OCAxNSA4WiIKICAgICAgICBzdHJva2U9IiM3Qzg0OTAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiAvPgo8L3N2Zz4="
            alt="search"
          />
        </div>

        <input
          onChange={handleSearchChange}
          id="search-items"
          name="search-items"
          placeholder={placeholder}
          value={searchValue}
          className={classnames(
            'pl-14 form-input block w-full py-4 px-3 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-gray-500',
            { [className]: true }
          )}
        />
      </div>
    </div>
  )
}

MSearchInput.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  onSearch: PropTypes.func,
  searchByKeys: PropTypes.arrayOf(PropTypes.string),
  numberOfCharsToSearch: PropTypes.number,
}
