import { CopyToClipboard } from 'react-copy-to-clipboard'

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}

const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000)
}

const fourteenDaysAgo = new Date()
fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14)

export const OProviderActivity = () => {
  const loggedInUser = 'You'

  const activities = Array.from({ length: 5 }, (_, i) => {
    const startTime = randomDate(fourteenDaysAgo, new Date())
    const endTime = addMinutes(startTime, Math.ceil(Math.random() * 13)) // end time within 13 minutes
    const chartInteractionTime = addMinutes(
      startTime,
      Math.ceil((Math.random() * (endTime.getTime() - startTime.getTime())) / 60000)
    ) // chart interaction within activity duration

    return {
      startTime,
      endTime,
      chartInteractionTime,
      providerName: ['Dr. Smith', 'Dr. Johnson', 'You'][Math.floor(Math.random() * 3)],
      cptCode: '12345',
      description: `Review of patient's progress`,
    }
  })

  const sortedActivities = [...activities].sort((a, b) => {
    if (a.startTime > b.startTime) return -1
    if (a.startTime < b.startTime) return 1
    if (a.endTime > b.endTime) return -1
    if (a.endTime < b.endTime) return 1
    return 0
  })

  return (
    <div className="react-web--admin__container">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Providers Activity
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Track and manage all provider interactions with patient charts.
        </p>
      </div>
      <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pb-10 sm:mt-12 sm:pt-12 lg:mx-0 lg:max-w-none lg:grid-cols-1">
        {sortedActivities.map((activity, i) => {
          const startDate = new Date(activity.startTime).toLocaleDateString('en-US')
          const startTime = new Date(activity.startTime).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          })
          const endTime = new Date(activity.endTime).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          })
          const interactionTime = Math.ceil(
            (new Date(activity.endTime).getTime() - new Date(activity.startTime).getTime()) / 60000
          )
          const name = activity.providerName === loggedInUser ? 'You' : activity.providerName
          const description = `Interaction started at ${startDate} ${startTime}, lasted ${interactionTime} minutes, ${activity.description}`

          return (
            <article
              key={i}
              className="flex max-w-xl flex-col items-start justify-between border-b border-gray-200 pb-6"
            >
              <div className="flex items-center gap-x-4 text-xs">
                <time className="text-gray-500">
                  {startDate} {startTime} - {endTime}
                </time>
              </div>
              <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  {name}
                </h3>
                <h4 className="mt-5 text-base font-bold leading-6 text-gray-600 group-hover:text-gray-600 ">
                  CPT Code: {activity.cptCode}
                </h4>
                <p className="mt-3 line-clamp-3 text-sm leading-6 text-gray-600">{description}</p>
              </div>
              <div className="relative mt-8 flex items-center gap-x-4">
                <CopyToClipboard text={activity.cptCode}>
                  <button className="mt-2 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
                    Copy CPT Code
                  </button>
                </CopyToClipboard>
                <CopyToClipboard text={description}>
                  <button className="mt-2 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
                    Copy Description
                  </button>
                </CopyToClipboard>
              </div>
            </article>
          )
        })}
      </div>
    </div>
  )
}
