import { parseTimestampDateFields } from './helpers.js'

export const parseGoal = data => {
  const dateToTimestampFields = ['startsOn', 'endsOn', 'createdOn', 'updatedOn']

  const goal = parseTimestampDateFields(data, dateToTimestampFields)

  return goal
}

export const parseGoalInstance = data => {
  const dateToTimestampFields = ['createdOn', 'updatedOn', 'startsOn', 'endsOn', 'lastTrackedOn']

  const goalInstance = parseTimestampDateFields(data, dateToTimestampFields)

  return goalInstance
}

export const parseGoalInstanceReminders = data => {
  const dateToTimestampFields = ['createdOn', 'whenToSend']

  const goalInstance = parseTimestampDateFields(data, dateToTimestampFields)

  return goalInstance
}
