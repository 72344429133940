import React, { useCallback, useState, useMemo } from 'react'
import classnames from 'classnames'
import { useForm, Controller } from 'react-hook-form'

import { AFormCheckboxItem } from '../atoms/AFormCheckboxItem.js'
import { MFormInput } from '../molecules/MFormInput.js'
import { MTabs } from '../molecules/MTabs.js'
import { OUserCalendar } from '../organisms/OUserCalendar.js'
import { OUserTimeline } from '../organisms/OUserTimeline.js'
import { OAssignContent } from '../organisms/OAssignContent.js'
import { OProviderActivity } from '../organisms/OProviderActivity.js'
import { OQuestionnaires } from '../organisms/OQuestionnaires'

import { Rings } from 'react-loader-spinner'

const AButton = ({
  icon,
  loadingIcon = <Rings type="Rings" color="white" height={16} width={16} />,
  loadingText = 'Loading...',
  overwriteStyles = false,
  isLoading,
  className = '',
  children,
  type = 'button',
  fullBorderRadius = false,
  primary = true,
  secondary = false,
  disabled = false,
  warning = false,
  onClick = () => {},
}) => {
  const buttonClassNames = overwriteStyles
    ? className
    : `cursor-pointer rounded-full inline-flex items-center px-4 py-1 border border-transparent text-gray-900 text-sm leading-5 font-medium ${
        primary && !secondary && !warning
          ? 'text-gray-900 bg-gray-300 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-700 active:bg-blue-700'
          : ''
      } ${
        secondary
          ? 'border border-gray-900 text-sm leading-5 font-medium rounded-full text-gray-900 bg-transparent hover:bg-white focus:outline-none focus:border-blue focus:shadow-outline-gray-300 active:bg-blue active:text-white font-semi'
          : ''
      } ${
        warning ? ' rounded-full text-white focus:outline-none border-red-400 text-red-400' : ''
      } ${className} ${disabled && 'opacity-50'}`

  return (
    <div className="react-web--admin__container">
      <button
        onClick={!isLoading ? onClick : undefined}
        type={type}
        className={classnames(buttonClassNames, { 'opacity-50 pointer-events-none': isLoading })}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <div className={'flex flex-col items-center w-full'}>
            <div className="flex flex-row items-center">
              {Boolean(loadingIcon) && <div className="mr-2">{loadingIcon}</div>}
              {Boolean(loadingText) && <span>{loadingText}</span>}
            </div>
          </div>
        ) : (
          <>
            {Boolean(icon) && <div className="mr-2">{icon}</div>}
            <span className="text-center w-full inline-block">{children}</span>
          </>
        )}
      </button>
    </div>
  )
}

export const OUser = ({
  user,
  users,
  isLoading,
  fullContent,
  isFullContentLoading,
  isUserContentLoading,
  assignedUserTopics,
  breadcrumbs,
  activeTab,
  isSuperAdmin,
  userGoalsInstances,
  userGoals,
  userQuestionnaires,
  assignedUserOnboardingMission,
  isDeleteSaving,
  showDeleteConfirmation,
  onTabChange = () => {},
  onShowDeleteConfirmation = () => {},
  onDeleteUser = () => {},
  onAddGoalToTheCalendar = () => {},
  onEditGoalInTheCalednaer = () => {},
  onDeleteGoalFromTheCalendar = () => {},
  onUserDeleted = () => {},
  onUpdateUser = () => {},
  onEditButton = () => {},
  onSaveUserContent = () => {},
}) => {
  const [isEditable, setIsEditable] = useState(false)

  const [isEditSaving, setIsEditSaving] = useState(false)

  const [editError, setEditError] = useState()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...user },
  })

  const userMissions = useMemo(() => {
    if (!assignedUserTopics?.length) return []

    return assignedUserTopics?.map(topic => topic.missionsAndSeries)?.flat()
  }, [assignedUserTopics])

  const handleSubmitEdit = useCallback(
    async data => {
      setEditError(null)
      setIsEditSaving(true)
      try {
        const hasEmailChanged = data.email !== user.email

        if (hasEmailChanged && users.find(user => user.email === data.email)) {
          throw new Error('User with this email already exists')
        }

        await onUpdateUser({
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          active: data.active,
        })

        setIsEditable(false)
      } catch (err) {
        setEditError(err?.message)
      }

      setIsEditSaving(false)
    },
    [onUpdateUser, user.email, users]
  )

  return (
    <div className="react-web--admin__container">
      <MTabs
        className="pt-5 pb-4 border-b border-gray px-7 -mx-7 -mt-12"
        activeTab={activeTab}
        onTabChange={onTabChange}
        tabs={[
          { id: 'program', name: 'Program' },
          { id: 'timeline', name: 'Timeline' },
          { id: 'calendar', name: 'Calendar' },
          { id: 'provider-activity', name: 'Provider Activities' },
          { id: 'questionnaires', name: 'Questionnaires' },
        ]}
      ></MTabs>

      <div className="pb-24 mt-8">
        <form onSubmit={handleSubmit(handleSubmitEdit)}>
          <div className="">
            <div className="py-5 flex flex-row justify-between items-center">
              <div>
                <h3 className="text-3xl leading-6 font-bold text-gray-900">
                  {user?.firstName} {user?.lastName}
                </h3>

                <h4 className="text-1xl mt-2 leading-6 font-regular text-gray-900">
                  {user?.organization?.name} - {user?.team?.name}
                </h4>

                <p className="mt-3 max-w-2xl text-sm text-gray-500">
                  Personal details & contact information.
                </p>

                <div className="w-auto inline-block mt-2">
                  {isEditable ? (
                    <Controller
                      name="active"
                      register={register('active')}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => {
                        return (
                          <AFormCheckboxItem
                            id="active"
                            name={'Active'}
                            checked={value}
                            onChange={onChange}
                            reversed
                          />
                        )
                      }}
                    />
                  ) : (
                    <dd className="text-sm text-gray-500 flex flex-row items-center justify-between ">
                      <div
                        className={classnames('w-3 h-3 mr-3 rounded-full', {
                          'bg-active': user.active,
                          'bg-red-400': !user.active,
                        })}
                      />
                      <span>{user.active ? 'Active' : 'Deactivated'}</span>
                    </dd>
                  )}
                </div>
              </div>

              <div className="flex flex-row items-center">
                <AButton onClick={onEditButton} secondary>
                  {!isEditable ? 'Edit' : 'Cancel Editting'}
                </AButton>
              </div>
            </div>
            <div className="border-t border-gray py-5">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="col-span-2 sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">First name</dt>

                  {isEditable ? (
                    <MFormInput
                      id="firstName"
                      type="text"
                      name="firstName"
                      errorMessage={errors?.firstName?.message}
                      className=" mt-1 w-full md:w-1/2 appearance-none block  px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      {...register('firstName', {
                        required: {
                          value: true,
                          message: 'This field is required',
                        },
                      })}
                    />
                  ) : (
                    <dd className="mt-1 text-sm text-gray-900">{user?.firstName}</dd>
                  )}
                </div>

                {/* <div className="col-span-2 sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Date of birth</dt>

            {isEditable ? (
              <MFormInput
                id="birthDate"
                type="text"
                name="birthDate"
                placeholder="Not assigned"
                disabled
                className=" mt-1 w-full md:w-1/2 appearance-none block  px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            ) : (
              <dd className="mt-1 text-sm text-gray-900">Not assigned</dd>
            )}
          </div> */}

                <div className="col-span-2 sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Last name</dt>

                  {isEditable ? (
                    <MFormInput
                      id="lastName"
                      type="text"
                      name="lastName"
                      errorMessage={errors?.lastName?.message}
                      className=" mt-1 w-full md:w-1/2 appearance-none block  px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      {...register('lastName', {
                        required: {
                          value: true,
                          message: 'This field is required',
                        },
                      })}
                    />
                  ) : (
                    <dd className="mt-1 text-sm text-gray-900">{user?.lastName}</dd>
                  )}
                </div>

                <div className="col-span-2 sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Email address</dt>

                  {isEditable ? (
                    <MFormInput
                      id="email"
                      type="email"
                      name="email"
                      errorMessage={errors?.email?.message}
                      className=" mt-1 w-full md:w-1/2 appearance-none block  px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      {...register('email', {
                        required: 'Invalid email address',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        },
                      })}
                    />
                  ) : (
                    <dd className="mt-1 text-sm text-gray-900">{user?.email}</dd>
                  )}
                </div>
                {/* <div className="col-span-2 sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Sex</dt>

            {isEditable ? (
              <MFormInput
                id="sex"
                type="text"
                name="sex"
                disabled
                placeholder="Not specified"
                className="w-full md:w-1/2 appearance-none block  px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            ) : (
              <dd className="mt-1 text-sm text-gray-900">Not specified</dd>
            )}
          </div> */}
                {/* 
          <div className="col-span-2 sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Notes</dt>

            <div className="w-full">
              {isEditable ? (
                <AFormTextArea
                  id="notes"
                  name="notes"
                  value="Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat.
              Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia
              proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu."
                  disabled
                  className=" mt-1 w-full md:w-1/2 appearance-none block  px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              ) : (
                <dd className="mt-1 text-sm text-gray-900 max-w-prose">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat.
                  Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia
                  proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>
              )}
            </div>
          </div> */}
              </dl>
            </div>

            {isEditable && (
              <div className="flex flex-row justify-end w-full p-4">
                <div className="flex flex-row items-center">
                  {!!editError && (
                    <span className="text-sm leading-5 font-medium rounded-md text-red-500 mr-4">
                      {editError}
                    </span>
                  )}
                  <AButton type="submit" isLoading={isEditSaving}>
                    Save changes
                  </AButton>
                </div>
              </div>
            )}
          </div>
        </form>

        {isEditable && isSuperAdmin && (
          <div className="flex flex-row justify-end p-8">
            {showDeleteConfirmation ? (
              <div className=" text-right flex flex-row justify-end items-center">
                <p className="text-sm leading-5 text-gray-600 mr-4">Are you sure?</p>
                <span className="inline-flex rounded-md shadow-sm">
                  <AButton secondary onClick={onShowDeleteConfirmation}>
                    Cancel
                  </AButton>
                </span>
                <span className=" inline-flex rounded-md shadow-sm ml-4">
                  <AButton warning onClick={onDeleteUser} isLoading={isDeleteSaving}>
                    Yes, delete user
                  </AButton>
                </span>
              </div>
            ) : (
              <AButton warning onClick={onShowDeleteConfirmation} isLoading={isDeleteSaving}>
                Delete user
              </AButton>
            )}
          </div>
        )}

        {!isLoading && !isUserContentLoading && (
          <>
            {activeTab === 'program' && (
              <OAssignContent
                key={user?.team?.id}
                assignedTopics={assignedUserTopics}
                assignedOnboardingMission={assignedUserOnboardingMission}
                allContentToAssign={fullContent}
                isLoading={isLoading}
                onSave={onSaveUserContent}
              />
            )}
            {activeTab === 'timeline' && (
              <OUserTimeline
                user={{
                  user,
                  didJoinOn: user?.didJoinOn ? user.didJoinOn.seconds * 1000 : 1677452400000,
                }}
                goals={userGoals}
                goalInstances={userGoalsInstances}
                missions={userMissions}
              />
            )}
            {activeTab === 'calendar' && (
              <OUserCalendar
                user={{
                  user,
                  didJoinOn: user?.didJoinOn ? user.didJoinOn.seconds * 1000 : 1677452400000,
                }}
                goals={userGoals}
                goalInstances={userGoalsInstances}
                missions={userMissions}
                onAddGoal={onAddGoalToTheCalendar}
                onEditGoal={onEditGoalInTheCalednaer}
                onGoalDelete={onDeleteGoalFromTheCalendar}
              />
            )}
            {activeTab === 'provider-activity' && <OProviderActivity />}
            {activeTab === 'questionnaires' && (
              <OQuestionnaires
                questionnaires={userQuestionnaires}
                missions={fullContent?.missions}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}
