import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import { MFormInput } from '../molecules/MFormInput.js'
import { AButton } from '../atoms/AButton.js'
import { AFormTextArea } from '../atoms/AFormTextarea.js'
import { AFormCheckboxItem } from '../atoms/AFormCheckboxItem.js'

export const OEditGoal = ({
  startDate,
  endDate,
  title,
  description,
  prompt,
  type,
  typeMeta,
  color,
  onSavingFinished = () => {},
}) => {
  const { trueLabel, falseLabel } = typeMeta || {}

  const [isEditSaving, setIsEditSaving] = useState(false)
  const [editError, setEditError] = useState()
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      startDate,
      endDate,
      title,
      description,
      prompt,
      trueLabel,
      falseLabel,
      isReminderEdit: false,
      reminderTitle: '',
      reminderSubtitle: '',
      reminderBody: '',
    },
  })
  const isReminderEditField = watch('isReminderEdit')

  const handleSubmitEdit = async data => {
    setEditError(null)
    setIsEditSaving(true)
    try {
      const {
        trueLabel,
        falseLabel,
        reminderTitle,
        reminderSubtitle,
        isReminderEdit,
        reminderBody,
        ...rest
      } = data

      const dataToSave = { ...rest }

      if (type === 'boolean') {
        const { trueLabel, falseLabel } = data

        dataToSave.typeMeta = { trueLabel, falseLabel }
      }

      if (isReminderEdit) {
        dataToSave.reminderData = {
          title: reminderTitle,
          subtitle: reminderSubtitle,
          body: reminderBody,
        }
      }

      await onSavingFinished(dataToSave)
    } catch (err) {
      setEditError(err?.message)
    }

    setIsEditSaving(false)
  }

  return (
    <div className="react-web--admin__container">
      <form className="h-full" onSubmit={handleSubmit(handleSubmitEdit)}>
        <div className="grid gap-y-4">
          <div className="flex items-center">
            <div
              className="w-6 h-6 border border-white rounded-full mr-4"
              style={{ backgroundColor: color }}
            />

            <div className="text-md font-medium text-gray-900">
              {!!startDate && (
                <span className="text-sm text-gray-500">{startDate.toISODate()}</span>
              )}

              {!!endDate && (
                <>
                  <span className="text-sm text-gray-500">{' - '}</span>
                  <span className="text-sm text-gray-500">{endDate.toISODate()}</span>
                </>
              )}
            </div>
          </div>

          <div>
            <MFormInput
              id="title"
              type="text"
              name="title"
              label="Title"
              placeholder="Drink 8 cups of water daily"
              errorMessage={errors?.title?.message}
              {...register('title', {
                required: {
                  value: true,
                  message: 'This field is required',
                },
              })}
            />
          </div>

          <div>
            <MFormInput
              id="prompt"
              type="text"
              name="prompt"
              label="Prompt"
              placeholder="Did you drink 8 cups of water today?"
              errorMessage={errors?.prompt?.message}
              {...register('prompt', {
                required: {
                  value: true,
                  message: 'This field is required',
                },
              })}
            />
          </div>

          {type === 'boolean' && (
            <div>
              <MFormInput
                id="trueLabel"
                type="text"
                name="trueLabel"
                label="Yes value"
                placeholder="Yes, I did"
                errorMessage={errors?.trueLabel?.message}
                {...register('trueLabel', {
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                })}
              />
            </div>
          )}

          {type === 'boolean' && (
            <div>
              <MFormInput
                id="falseLabel"
                type="text"
                name="falseLabel"
                label="No value"
                placeholder="No, I didn't"
                errorMessage={errors?.falseLabel?.message}
                {...register('falseLabel', {
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                })}
              />
            </div>
          )}

          <div>
            <AFormTextArea
              id="description"
              type="text"
              name="description"
              label="Description"
              placeholder="Goal description"
              className=" mt-1 w-full appearance-none block px-3 py-2"
              errorMessage={errors?.description?.message}
              {...register('description', {
                required: {
                  value: true,
                  message: 'This field is required',
                },
              })}
            />
          </div>

          <div className="my-2 border-b border-gray w-full" />

          <Controller
            name="isReminderEdit"
            register={register('isReminderEdit')}
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => {
              return (
                <AFormCheckboxItem
                  id="isReminderEdit"
                  name={'Modify reminders'}
                  checked={value}
                  onChange={onChange}
                  reversed
                />
              )
            }}
          />

          {isReminderEditField && (
            <>
              <div>
                <MFormInput
                  id="reminderTitle"
                  type="text"
                  name="reminderTitle"
                  label="Reminder title"
                  placeholder="Reminder title"
                  errorMessage={errors?.reminderTitle?.message}
                  {...register('reminderTitle', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  })}
                />
              </div>

              <div>
                <MFormInput
                  id="reminderSubtitle"
                  type="text"
                  name="reminderSubtitle"
                  label="Reminder subtitle"
                  placeholder="Reminder subtitle"
                  errorMessage={errors?.reminderSubtitle?.message}
                  {...register('reminderSubtitle', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  })}
                />
              </div>

              <div>
                <AFormTextArea
                  id="reminderBody"
                  type="text"
                  name="reminderBody"
                  label="Reminder description"
                  placeholder="Reminder description"
                  className=" mt-1 w-full appearance-none block px-3 py-2"
                  errorMessage={errors?.reminderBody?.message}
                  {...register('reminderBody', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  })}
                />
              </div>
            </>
          )}
        </div>

        <div className="flex flex-col mt-6">
          <div className="flex flex-col items-center">
            <AButton type="submit" className="w-full" isLoading={isEditSaving}>
              Save changes
            </AButton>

            {!!editError && (
              <div className="text-sm leading-5 mt-2 font-medium text-red-500">{editError}</div>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

OEditGoal.propTypes = {
  startDate: PropTypes.object, // Luxon DateTime
  endDate: PropTypes.object, // Luxon DateTime
  title: PropTypes.string,
  description: PropTypes.string,
  prompt: PropTypes.string,
  onSavingFinished: PropTypes.func,
  typeMeta: PropTypes.object,
  color: PropTypes.string,
}
