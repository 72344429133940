import { useMemo } from 'react'
import { doc, collection, addDoc, updateDoc } from 'firebase/firestore'

import { auth, firestoreDb, COLLECTIONS, doesOrganizationExistsFbFunction } from './firebase.js'
import { useStore } from '../stores/createStore.js'

export const useTeamsForOrganizations = organizations => {
  const { teamsRaw, isTeamsLoading } = useStore(state => ({
    teamsRaw: state.teams,
    isTeamsLoading: state.isTeamsLoading,
  }))

  const teams = useMemo(
    () => teamsRaw.filter(team => organizations.find(org => org.id === team.organizationId)),
    [teamsRaw, organizations]
  )
  return useMemo(() => [teams, isTeamsLoading], [teams, isTeamsLoading])
}

export const useUsersForOrganizations = organizations => {
  const { usersRaw, isUsersLoading } = useStore(state => ({
    usersRaw: state.users,
    isUsersLoading: state.isUsersLoading,
  }))

  const users = useMemo(
    () => usersRaw.filter(user => organizations.find(org => org.id === user.organizationId)),
    [usersRaw, organizations]
  )

  return useMemo(() => [users, isUsersLoading], [users, isUsersLoading])
}

export const useOrganizations = () => {
  const { organizationsRaw, isOrganizationsRawLoading } = useStore(state => ({
    organizationsRaw: state.organizations,
    isOrganizationsRawLoading: state.isOrganizationsLoading,
  }))

  const [users, isUsersLoading, isUsersError] = useUsersForOrganizations(organizationsRaw)
  const [teams, isTeamsLoading, isTeamsError] = useTeamsForOrganizations(organizationsRaw)

  const isLoading = isOrganizationsRawLoading || isUsersLoading || isTeamsLoading
  const isError = isUsersError || isTeamsError

  const organizations = useMemo(
    () =>
      organizationsRaw && users && teams
        ? organizationsRaw
            .map(data => {
              const id = data.id
              const organizationUsers = users.filter(user => user.organizationId === id)
              const invitations = organizationUsers.filter(user => !user.didJoin).length

              return {
                ...data,
                createdAt: data.createdAt
                  ? data.createdAt?.toDate
                    ? data.createdAt.toDate()
                    : data.createdAt
                  : null,
                id,
                users: organizationUsers,
                teams: teams.filter(team => team.organizationId === id),
                invitations,
              }
            })
            .sort((a, b) => a.priority - b.priority)
        : null,
    [organizationsRaw, users, teams]
  )

  return useMemo(() => [organizations, isLoading, isError], [organizations, isLoading, isError])
}

export const useOrganization = organizationId => {
  const { organizations, isOrganizationsLoading } = useStore(state => ({
    organizations: state.organizations,
    isOrganizationsLoading: state.isOrganizationsLoading,
  }))

  const organization = useMemo(() => {
    return organizations.find(org => org.id === organizationId)
  }, [organizationId, organizations])

  return [organization, isOrganizationsLoading]
}

export const addOrganization = async organizationData => {
  if (!organizationData) throw new Error('No organization specified')
  if (!organizationData.name) throw new Error('Organization must have a name')

  const result = await doesOrganizationExistsFbFunction({ name: organizationData.name })
  const { organizationExists } = result.data

  if (!organizationExists) {
    const organizationRef = collection(firestoreDb, COLLECTIONS.organizations)

    await addDoc(organizationRef, {
      ...organizationData,
      createdBy: auth.currentUser.uid,
      createdAt: new Date(),
    })
  } else {
    throw new Error('Organization already exists')
  }
}

export const updateOrganization = async organization => {
  if (!organization) throw new Error('No organization specified')
  if (!organization.id) {
    throw new Error('organization must have an id')
  }

  const result = await doesOrganizationExistsFbFunction({ id: organization.id })

  const { organizationExists } = result.data

  if (organizationExists) {
    const { id, ...data } = organization
    const organizationRef = doc(firestoreDb, COLLECTIONS.organizations, organization.id)

    return updateDoc(organizationRef, data)
  } else {
    throw new Error('Organization does not exist')
  }
}
