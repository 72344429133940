import { create } from 'zustand'
import { query, doc, getDoc, getDocs, collection, onSnapshot, where } from 'firebase/firestore'

import { firestoreDb, COLLECTIONS } from '../services/firebase.js'
import _ from 'lodash'

const checkIfMobile = () => window.matchMedia('only screen and (max-width: 767px)').matches

const FIREBASE_QUERY_LIMIT = 10
let useStore = null

const createStore = ({ adminAccessToken }) => {
  if (useStore) return useStore

  useStore = create((set, get) => ({
    adminUser: null,
    organizations: null,
    isOrganizationsLoading: true,
    teams: null,
    isTeamsLoading: true,
    users: null,
    archivedUsers: null,
    isUsersLoading: true,
    isArchivedUsersLoading: true,
    adminUsers: null,
    isAdminUsersLoading: true,
    acl: null,
    isAclLoading: true,
    claims: null,
    isSuperAdmin: false,
    isTeacherAdmin: false,
    isMobileSideContentExpanded: false,
    isMobile: checkIfMobile(),

    unsubscribeOrganizationsQueryListener: () => {
      console.log('Unsibscribe on ORGANIZATIONS listerner called but not set yet')
    },
    unsubscribeTeamsQueryListener: () => {
      console.log('Unsibscribe on TEAMS listerner called but not set yet')
    },
    unsubscribeUsersQueryListener: () => {
      console.log('Unsibscribe on USERS listerner called but not set yet')
    },
    unsubscribeAclQueryListener: () => {
      console.log('Unsibscribe on ACL listerner called but not set yet')
    },

    subscribeCollectionDocuments: async ({
      ids,
      collectionName,
      shouldFetchAll,
      storeName,
      storeLoadingName,
      storeUnsubscribeName,
      sortBy = 'name',
      queryBy = '__name__',
      additionalFilterByFields = [],
      additionalFilterByValues = [],
      transformDocumentValues = val => val,
    }) => {
      if (additionalFilterByFields?.length !== additionalFilterByValues?.length) {
        throw new Error(
          'additionalFilterByFields and additionalFilterByValues must have the same length to match'
        )
      }

      if (shouldFetchAll) {
        const documentsQuery = query(collection(firestoreDb, collectionName))

        const unsubscribe = onSnapshot(documentsQuery, querySnapshot => {
          const documents = []
          querySnapshot.forEach(doc => {
            documents.push({ ...doc.data(), id: doc.id })
          })

          set({
            [storeName]: _.sortBy(documents, sortBy),
            [storeLoadingName]: false,
          })
        })
        set({ [storeUnsubscribeName]: unsubscribe })

        return
      }

      if (!ids?.length) {
        set({ [storeLoadingName]: false, [storeName]: [] })
        return
      }

      const chunkedArrayOfIds = []
      const snapshotListeners = []
      let currentChunkIndex = 0

      ids.forEach((org, index) => {
        if (!chunkedArrayOfIds[currentChunkIndex]) {
          chunkedArrayOfIds[currentChunkIndex] = []
          snapshotListeners[currentChunkIndex] = null // null for now, later will be made as a func
        }

        chunkedArrayOfIds[currentChunkIndex].push(org)

        if (chunkedArrayOfIds[currentChunkIndex].length === FIREBASE_QUERY_LIMIT) {
          currentChunkIndex++
        }
      })

      const fetchedDocumentsChunks = []

      for (let i = 0; i < chunkedArrayOfIds.length; i++) {
        const chunk = chunkedArrayOfIds[i]

        const documentsQuery = query(
          collection(firestoreDb, collectionName),
          where(queryBy, 'in', chunk)
        )

        // thanks to es6 Infamous Loop Problem is no longer an issue
        snapshotListeners[i] = onSnapshot(documentsQuery, querySnapshot => {
          fetchedDocumentsChunks[i] = {}

          querySnapshot.forEach(doc => {
            const data = doc.data()
            const id = doc.id

            fetchedDocumentsChunks[i][id] = { ...data, id }
          })

          const isAllDocumentsLoaded = fetchedDocumentsChunks.length === chunkedArrayOfIds.length

          if (isAllDocumentsLoaded) {
            let documentsToStore = fetchedDocumentsChunks
              .map((docObject, index) => {
                return Object.values(docObject)
              })
              .flat()
              .map(transformDocumentValues)

            if (additionalFilterByFields?.length && additionalFilterByValues?.length) {
              for (let i = 0; i < additionalFilterByFields.length; i++) {
                const field = additionalFilterByFields[i]
                const values = additionalFilterByValues[i]

                documentsToStore = documentsToStore.filter(doc => values.includes(doc[field]))
              }
            }

            set({
              [storeLoadingName]: false,
              [storeName]: _.sortBy(documentsToStore, sortBy),
            })
          }
        })
      }

      if (!fetchedDocumentsChunks.length) {
        set({ [storeLoadingName]: false, [storeName]: [] })
      }

      const unsubscribe = () => {
        snapshotListeners.forEach((listener, index) => {
          if (listener) listener()
          listener = null
        })
      }

      set({ [storeUnsubscribeName]: unsubscribe })
    },

    /**
     * Load all ORGANIZATIONS and listen for changes
     */
    loadOrganizations: ({ organizations, shouldFetchAll }) => {
      const isSuperAdmin = get().isSuperAdmin

      get().subscribeCollectionDocuments({
        ids: organizations,
        collectionName: 'organizations',
        shouldFetchAll: isSuperAdmin,
        storeName: 'organizations',
        storeLoadingName: 'isOrganizationsLoading',
        storeUnsubscribeName: 'unsubscribeOrganizationsQueryListener',
      })
    },

    /**
     * Load all TEAMS and listen for changes
     */
    loadTeams: ({ organizations, teams }) => {
      const isSuperAdmin = get().isSuperAdmin

      if (teams?.length) {
        get().subscribeCollectionDocuments({
          ids: teams,
          collectionName: 'teams',
          shouldFetchAll: isSuperAdmin,
          storeName: 'teams',
          storeLoadingName: 'isTeamsLoading',
          storeUnsubscribeName: 'unsubscribeTeamsQueryListener',
        })
      } else {
        get().subscribeCollectionDocuments({
          ids: organizations,
          collectionName: 'teams',
          shouldFetchAll: isSuperAdmin,
          storeName: 'teams',
          storeLoadingName: 'isTeamsLoading',
          storeUnsubscribeName: 'unsubscribeTeamsQueryListener',
          queryBy: 'organizationId',
        })
      }
    },


    /**
     * Load all ARCHIVED USERS and listen for changes
     */
    loadArchivedUsers: ({ organizations, teams }) => {
      const isSuperAdmin = get().isSuperAdmin

      get().subscribeCollectionDocuments({
        ids: organizations,
        collectionName: 'archivedUsers',
        shouldFetchAll: isSuperAdmin,
        storeName: 'archivedUsers',
        storeLoadingName: 'isArchivedUsersLoading',
        storeUnsubscribeName: 'unsubscribeUsersQueryListener',
        queryBy: 'organizationId',
        additionalFilterByFields: teams?.length ? ['teamId'] : null,
        additionalFilterByValues: teams?.length ? [teams] : null,
        sortBy: 'firstName',
      })
    },


    /**
     * Load all USERS and listen for changes
     */
    loadUsers: ({ organizations, teams }) => {
      const isSuperAdmin = get().isSuperAdmin

      get().subscribeCollectionDocuments({
        ids: organizations,
        collectionName: 'users',
        shouldFetchAll: isSuperAdmin,
        storeName: 'users',
        storeLoadingName: 'isUsersLoading',
        storeUnsubscribeName: 'unsubscribeUsersQueryListener',
        queryBy: 'organizationId',
        additionalFilterByFields: teams?.length ? ['teamId'] : null,
        additionalFilterByValues: teams?.length ? [teams] : null,
        sortBy: 'firstName',
      })
    },
    loadAdminUsers: async () => {
      const isSuperAdmin = get().isSuperAdmin

      await get().subscribeCollectionDocuments({
        ids: null,
        collectionName: 'adminUsers',
        shouldFetchAll: isSuperAdmin,
        storeName: 'adminUsers',
        storeLoadingName: 'isAdminUsersLoading',
        storeUnsubscribeName: 'unsubscribeUsersQueryListener',
        sortBy: 'firstName',
      })
    },
    loadAcl: async () => {
      const isSuperAdmin = get().isSuperAdmin

      await get().subscribeCollectionDocuments({
        ids: null,
        collectionName: 'acl',
        shouldFetchAll: isSuperAdmin,
        storeName: 'acl',
        storeLoadingName: 'isAclLoading',
        storeUnsubscribeName: 'unsubscribeAclQueryListener',
        transformDocumentValues: doc => {
          let aclDoc = { ...doc }

          // if (aclDoc.acl) {
          //   aclDoc.acl = Object.keys(aclDoc.acl)
          // }

          return aclDoc
        },
      })
    },
    loadAdminUser: async uid => {
      const adminUserRef = doc(firestoreDb, COLLECTIONS.adminUsers, uid)
      const adminUserSnap = await getDoc(adminUserRef)
      const adminUser = adminUserSnap.data()
      set({ adminUser: { id: uid, ...adminUser } })
    },
    setAdminAccess: claims => {
      // TODO: axios access store outside of react context instead of saving in localStorage
      localStorage.setItem(adminAccessToken, claims.adminAccessToken)
      set({
        claims,
        isSuperAdmin: claims?.role === 'super_admin',
        isTeacherAdmin: claims?.role === 'teacher_admin',
        readOnly: claims?.role === 'teacher_admin',
      })
    },
    setMobileSideContent: (isExpanded = true) => {
      set({ isMobileSideContentExpanded: isExpanded })
    },

    /**
     * CLEANUP
     */
    unsubscribeListeners: () => {
      console.log('Store unsunbscribing from listeners')
      get().unsubscribeOrganizationsQueryListener()
      get().unsubscribeTeamsQueryListener()
      get().unsubscribeUsersQueryListener()
      get().unsubscribeAclQueryListener()
    },
    cleanStore: () => {
      get().unsubscribeListeners()
      set({
        organizations: null,
        isOrganizationsLoading: true,
        teams: null,
        isTeamsLoading: true,
        users: null,
        isUsersLoading: true,
        claims: null,
        isSuperAdmin: false,
      })
      localStorage.removeItem(adminAccessToken)
    },
    subscribeMobileDetectionListener: () => {
      set({ isMobile: checkIfMobile() })

      window.addEventListener('resize', () => {
        set({ isMobile: checkIfMobile() })
      })
    },
  }))

  return useStore
}

export { createStore, useStore }
