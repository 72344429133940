import React, { useEffect, useCallback, useState, useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'

import { MAssignTopicListItem } from '../molecules/MAssignTopicListItem.js'
import { MGridItem } from '../molecules/MGridItem'
import { MTableRowWithSelect } from '../molecules/MTableRowWithSelect'
import { sortTopicsArray } from '@edwin/sdk-admin'

const SortableAssignedTopic = ({
  id,
  topic,
  index,
  onToggleMission,
  isDragDisabled = false,
  isExpanded = true,
  onDeleteTopic,
  expandedTopicId,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
    disabled: isDragDisabled,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <MAssignTopicListItem
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
      index={index}
      topic={topic}
      onToggleMission={onToggleMission}
      isExpanded={isExpanded}
      isDragDisabled={isDragDisabled}
      onDeleteTopic={onDeleteTopic}
      expandedTopicId={expandedTopicId}
    />
  )
}

export const OAddTeamAssignContent = ({
  assignedTopics,
  allContentToAssign,
  assignedOnboardingMission = null,
  onSave = () => {},
  allowCustomSaveAssignments,
  ctaReorderLabel = 'Reorder categories',
  ctaCancelReorderLabel = 'Cancel reorder',
  children,
  className,
}) => {
  const [localAssignedTopics, setLocalAssignedTopics] = useState([])
  const [notAssignedTopic, setNotAssignedTopics] = useState([])

  const [localAssignedOnboardingMission, setLocalAssignedOnboardingMission] =
    useState(assignedOnboardingMission)

  const [isSaving, setIsSaving] = useState(false)
  const [isTopicDraggingAllowed, setIsTopicDraggingAllowed] = useState(true)
  const [draggedTopic, setDraggedTopic] = useState(false)
  const [expandedTopicId, setExpandedTopicId] = useState(null)
  const canRefreshAssignedTopics = useRef(true)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragStart = useCallback(
    event => {
      const { active } = event

      if (active) {
        const currentlyDraggedTopic = localAssignedTopics.find(item => item.id === active.id)

        setDraggedTopic(currentlyDraggedTopic)
      }
    },
    [localAssignedTopics]
  )

  const handleDragEnd = useCallback(event => {
    const { active, over } = event

    if (active.id !== over.id) {
      setLocalAssignedTopics(items => {
        const oldIndex = items.findIndex(item => item.id === active.id)
        const newIndex = items.findIndex(item => item.id === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
      setDraggedTopic(false)
    }
  }, [])

  const handleToggleExpandedTopic = useCallback(
    topicId => {
      setExpandedTopicId(expandedTopicId === topicId ? null : topicId)
    },
    [expandedTopicId]
  )

  const handleToggleMission = useCallback((topicId, tmsId) => {
    setLocalAssignedTopics(localAssignedTopics => {
      return localAssignedTopics.map(topic => {
        const topicObj = { ...topic }

        if (topic.id === topicId) {
          return {
            ...topicObj,
            missionsAndSeries: topicObj.missionsAndSeries.map(tms => {
              let tmsObj = { ...tms }

              if (tmsObj.id === tmsId) {
                tmsObj.isAssigned = !tmsObj.isAssigned
              }

              return tmsObj
            }),
          }
        }

        return topicObj
      })
    })
  }, [])

  const handleAddTopic = useCallback(
    topicId => {
      setLocalAssignedTopics(prevState => {
        const array = [...prevState]
        const addedTopic = allContentToAssign.topics.find(topic => topic.id === topicId)

        if (addedTopic) {
          array.push(addedTopic)
        }

        return array
      })
    },
    [allContentToAssign]
  )

  const handleDeleteTopic = useCallback(topicId => {
    setLocalAssignedTopics(prevState => {
      const array = [...prevState].filter(topic => topic.id !== topicId)

      return array
    })
  }, [])

  const handleAddOnboardingMission = useCallback(
    missionId => {
      const missionToAssign = allContentToAssign?.onboardingMissions?.find(
        mission => mission.id === missionId
      )

      if (missionToAssign) {
        setLocalAssignedOnboardingMission(missionToAssign)
      }
    },
    [allContentToAssign?.onboardingMissions]
  )

  const handleSaveAssignment = useCallback(async () => {
    canRefreshAssignedTopics.current = false
    setIsSaving(true)

    const parsedTopics = localAssignedTopics
      .map(topic => {
        return {
          id: topic.id,
          missionsAndSeries: topic?.missionsAndSeries?.map(tms => {
            return {
              id: tms.id,
              type: tms.type,
              isAssigned: tms.isAssigned !== false,
            }
          }),
        }
      })
      .filter(topic => {
        if (!topic?.missionsAndSeries?.length) {
          return false
        }

        return true
      })

    const onboardingMission = { ...localAssignedOnboardingMission }

    await onSave({ topics: parsedTopics, onboardingMission })

    setIsSaving(false)
    canRefreshAssignedTopics.current = true

    return { topics: parsedTopics, onboardingMission }
  }, [localAssignedTopics, localAssignedOnboardingMission, onSave])

  useEffect(() => {
    if (assignedTopics && canRefreshAssignedTopics.current) {
      setLocalAssignedTopics(assignedTopics)
    }
  }, [assignedTopics, allContentToAssign?.topics, isSaving])

  useEffect(() => {
    const allTopics = allContentToAssign?.topics

    if (allTopics) {
      setNotAssignedTopics(
        allTopics
          .filter(topic => {
            return !localAssignedTopics.find(assignedTopic => assignedTopic.id === topic.id)
          })
          .sort(sortTopicsArray)
      )
    }
  }, [localAssignedTopics, allContentToAssign?.topics])

  return (
    <div className="react-web--admin__container">
      {allowCustomSaveAssignments ? (
        <div className={classnames('', { [className]: !!className })}>
          {!!allContentToAssign?.onboardingMissions?.length && (
            <div className="mt-6">
              <h3 className="block text-xl font-bold leading-6 text-gray-900">Onboarding</h3>

              <div className="mt-2 lg:mt-6 ring-1 ring-gray-300 rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody>
                    {allContentToAssign?.onboardingMissions?.map((onboardingMission, index) => (
                      <MTableRowWithSelect
                        key={onboardingMission.id}
                        title={onboardingMission.title || onboardingMission.name}
                        activeItem={localAssignedOnboardingMission?.id === onboardingMission.id}
                        firstRow={index === 0}
                        onClick={() => handleAddOnboardingMission(onboardingMission.id)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="mt-12">
            <h3 className="block text-xl font-bold leading-6 text-gray-900">
              Assigned program ({localAssignedTopics.length || 0})
            </h3>
          </div>

          {!!localAssignedTopics.length ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext items={localAssignedTopics} strategy={verticalListSortingStrategy}>
                {localAssignedTopics.map((topic, index) => {
                  return (
                    <div onClick={() => handleToggleExpandedTopic(topic.id)} key={topic.id}>
                      <SortableAssignedTopic
                        key={topic.id}
                        id={topic.id}
                        topic={topic}
                        index={index}
                        onToggleMission={handleToggleMission}
                        isDragDisabled={!isTopicDraggingAllowed}
                        isExpanded={!isTopicDraggingAllowed}
                        onDeleteTopic={handleDeleteTopic}
                        expandedTopicId={expandedTopicId}
                      >
                        {topic.name}
                      </SortableAssignedTopic>
                    </div>
                  )
                })}
              </SortableContext>
            </DndContext>
          ) : (
            <span className="inline-block mt-2 text-gray-500 font-light">No topics assigned</span>
          )}

          {!!notAssignedTopic?.length && (
            <div className="mt-12">
              <h3 className="block text-xl font-bold leading-6 text-gray-900">
                Available to assign
              </h3>

              <ul className="mt-2 lg:mt-6 rounded-lg overflow-hidden">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 -m-px">
                  {notAssignedTopic?.map(notAssignedTopic => (
                    <li
                      key={notAssignedTopic.id}
                      className="col-span-1 border-[0.5px] border-gray-200"
                    >
                      <MGridItem
                        title={notAssignedTopic.title || notAssignedTopic.name}
                        description={notAssignedTopic.description}
                        numberOfAssignments={`${
                          notAssignedTopic.missionsAndSeries?.length || 0
                        } assignments`}
                        onClick={() => handleAddTopic(notAssignedTopic.id)}
                      />
                    </li>
                  ))}
                </div>
              </ul>
            </div>
          )}
        </div>
      ) : (
        <div className={classnames('', { [className]: !!className })} />
      )}

      {!!children && <div>{children({ getAssignedContent: handleSaveAssignment })}</div>}
    </div>
  )
}

OAddTeamAssignContent.propTypes = {
  assignedTopics: PropTypes.array,
  assignedOnboardingMission: PropTypes.object,
  allContentToAssign: PropTypes.object,
  onSave: PropTypes.func,
  ctaReorderLabel: PropTypes.string,
  ctaCancelReorderLabel: PropTypes.string,
  children: PropTypes.func,
}
