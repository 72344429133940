import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { AButton } from '../atoms/AButton.js'
import { MEmptyContentPlaceholder } from '../molecules/MEmptyContentPlaceholder.js'
import { MCard } from '../molecules/MCard'
import { MSearchInput } from '../molecules/MSearchInput.js'

export const OOrganizationTeamsList = ({
  activeTeamId,
  teams,
  label = 'Group',
  onAddTeamClick,
  onTeamClick,
  isSearchable = false,
  readOnly,
}) => {
  const [visibleTeams, setVisibleTeams] = useState(teams)

  const handleSearchTeams = useCallback(searchedTeam => {
    setVisibleTeams(searchedTeam)
  }, [])

  useEffect(() => {
    setVisibleTeams(teams)
  }, [teams])

  return (
    <div className="react-web--admin__container">
      <div className="mb-6 flex items-center justify-between">
        <h3 className="text-base font-semibold uppercase tracking-widest">{label}s</h3>
        {!readOnly && (
          <div>
            <AButton onClick={onAddTeamClick}>Add {label}</AButton>
          </div>
        )}
      </div>

      {isSearchable && (
        <MSearchInput
          items={teams}
          className="mb-6"
          placeholder="Search Teams"
          onSearch={handleSearchTeams}
          searchByKeys={['name', 'id']}
          numberOfCharsToSearch={2}
          key={activeTeamId}
        />
      )}

      <ul className="">
        {visibleTeams?.map((team, index) => {
          const isActive = team.id === activeTeamId

          return (
            <li key={team.id} className="w-full">
              <MCard
                icon={<span>{team.name.charAt(0)}</span>}
                title={team.name}
                items={[
                  {
                    label: 'Active Users',
                    value: team.users.length,
                  },
                  {
                    label: 'Pending Invitations',
                    value: team.invitations,
                  },
                ]}
                className="mb-4"
                isActive={isActive}
                onClick={() => onTeamClick(team)}
              />
            </li>
          )
        })}
      </ul>

      {!visibleTeams.length && (
        <div className="mt-12">
          <MEmptyContentPlaceholder description="No groups assigned" />
        </div>
      )}
    </div>
  )
}

OOrganizationTeamsList.propTypes = {
  activeTeamId: PropTypes.string,
  teams: PropTypes.array,
  onAddTeamClick: PropTypes.func,
  onTeamClick: PropTypes.func,
  readOnly: PropTypes.bool,
}
