import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { sendSignInLinkToEmail } from 'firebase/auth'

import { AButton, MFormInput } from '@edwin/react-web-admin'

import { auth, doesAdminUserExistsFbFunction } from '@services/firebase'
import Config from '@config/Config'

export default function PSignIn() {
  const { register, handleSubmit } = useForm()
  const [isWorking, setIsWorking] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [errorMsg, setErrorMsg] = useState()

  const sendEmailSignInLink = async data => {
    try {
      setIsWorking(true)
      setErrorMsg(false)

      const result = await doesAdminUserExistsFbFunction({ email: data.email })
      const { userExists } = result.data

      if (userExists) {
        const actionCodeSettings = {
          url: `${Config.domain}/sign-in-with-email-link/`,
          handleCodeInApp: true,
        }

        await sendSignInLinkToEmail(auth, data.email, actionCodeSettings)

        window.localStorage.setItem('emailForSignIn', data.email)

        setIsEmailSent(true)

        setIsWorking(false)
      } else {
        setErrorMsg('There is no user with that email. Please make sure the email is correct.')
        setIsWorking(false)
      }
    } catch (err) {
      setErrorMsg(err.message)
      setIsWorking(false)
    }
  }

  return (
    <div className="min-h-screen bg-white sm:bg-hub-dark flex flex-col sm:justify-center">
      <div className="sm:mx-auto">
        <div className="bg-white flex flex-col sm:flex-row items-center justify-center sm:mx-8 relative">
          <div className="py-24 sm:py-12 mx-auto px-4 sm:pl-16 sm:pr-0 sm:-mr-4 sm:max-w-sm sm:flex-shrink-0 relative">
            <form onSubmit={handleSubmit(sendEmailSignInLink)} method="POST">
              <h3 className="text-4xl break-words font-semibold mb-9 leading-snug text-hub-dark">
                Generate your sign-in link
              </h3>

              <MFormInput
                id="email"
                type="email"
                name="email"
                // errorMessage={errors?.firstName?.message}
                disabled={isEmailSent}
                placeholder="Your email address"
                {...register('email', {
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                })}
              />

              {!isEmailSent && (
                <div className="mt-6">
                  <AButton
                    type="submit"
                    loadingIcon={null}
                    isLoading={isWorking}
                    loadingText="Working..."
                    fullBorderRadius
                    className="w-full"
                  >
                    Generate
                  </AButton>
                  <div className="mt-6">
                    <div className="mt-6 flex flex-1 flex-grow">
                      <p className=" text-xs text-hub-dark-gray mx-auto">
                        We will send a sign-in link to this email. Just click on that link to
                        sign-in.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </form>
            {errorMsg && (
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm leading-5">
                    <span className="px-2 bg-white text-gray-500">Error</span>
                  </div>
                </div>

                <div className="mt-6 flex flex-1 flex-grow">
                  <p className="text-red-600 text-sm mx-auto">{errorMsg}</p>
                </div>
              </div>
            )}
            {isEmailSent && (
              <div className="mt-4 flex flex-1 flex-grow flex-col items-start justify-start">
                <p className="text-sm font-medium leading-5 text-gray-500">Link was sent</p>
                <p className="mt-1 text-xs leading-5 text-hub-dark-gray">
                  Check your inbox and click the link to sign-in
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
