import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export const AAvatar = ({ firstName = '', lastName = '', className }) => {
  return (
    <div className="react-web--admin__container">
      <div
        className={classnames(
          'flex items-center justify-center w-8 h-8 font-semi text-xs text-gray-900 rounded-full bg-white border border-gray-900',
          {
            [className]: !!className,
          }
        )}
      >
        {firstName.charAt(0).toUpperCase()} {lastName.charAt(0).toUpperCase()}
      </div>
    </div>
  )
}

AAvatar.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  className: PropTypes.string,
}
