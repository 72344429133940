import { getAxiosInstance } from './axios.js'
import { mapFirebaseMissionAndSerie, sortTopicsArray } from '../utils/helpers.js'

export const fetchAllContent = async () => {
  const allContentResponse = await getAxiosInstance().get('/admin/all-content')

  const allContentDataRaw = allContentResponse?.data

  const topics = allContentDataRaw?.topics || []
  const missions = allContentDataRaw?.missions || []
  const series = allContentDataRaw?.series || []

  const allContent = {
    ...allContentDataRaw,
    topics: topics
      .map(topic => ({
        ...topic,
        missionsAndSeries: topic?.missionsAndSeries
          ?.map(currentMission => mapFirebaseMissionAndSerie(currentMission, missions, series))
          .filter(tms => tms),
      }))
      .sort((a, b) => a.priority - b.priority),
    series: series.map(serie => ({
      ...serie,
      missions: serie?.missions
        ?.map(currentMission => mapFirebaseMissionAndSerie(currentMission, missions, series))
        .filter(tms => tms),
    })),
  }

  return allContent
}

export const getDefaultOrganizationContentToAssign = async () => {
  const content = await fetchAllContent()

  const assignedTopics = content.topics
    .map(topic => {
      return {
        ...topic,
        isAssigned: true,
        missionsAndSeries: topic?.missionsAndSeries?.map(missionOrSerie => ({
          ...missionOrSerie,
          isAssigned: true,
        })),
      }
    }, [])
    .sort(sortTopicsArray)

  const assignedOnboardingMission = content.onboardingMissions?.[0]

  return { content, assignedTopics, assignedOnboardingMission }
}
