import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { AFormInputWrapper } from '../atoms/AFormInputWrapper.js'

export const MFormInput = React.forwardRef(
  ({ label, id, name, disabled, className, errorMessage, labelClassName, ...props }, ref) => {
    return (
      <div className="react-web--admin__container">
        <AFormInputWrapper
          labelClassName={labelClassName}
          label={label}
          id={id}
          name={name}
          errorMessage={errorMessage}
        >
          <input
            id={id}
            name={name}
            disabled={disabled}
            ref={ref}
            className={classnames(
              'block form-input mt-1 py-2 px-3 w-full sm:text-sm sm:leading-6 placeholder:text-gray-400 rounded-md border-0 shadow-sm text-gray-900 ring-1 ring-inset focus:ring-2 focus:ring-inset transition duration-150 ease-in-out disabled:bg-white disabled:text-gray-600',
              {
                [className]: true,
                'ring-gray-300 focus:ring-accent': !errorMessage,
                'ring-red-400': !!errorMessage,
              }
            )}
            {...props}
          />
        </AFormInputWrapper>
      </div>
    )
  }
)

MFormInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
}
