import React, { useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import classnames from 'classnames'

import { AButton } from '../atoms/AButton.js'
import { AFormCheckboxItem } from '../atoms/AFormCheckboxItem.js'
import { MFormOrganizationsCheckboxList } from '../molecules/MFormOrganizationsCheckboxList.js'
import { MFormSelect } from '../molecules/MFormSelect.js'
import { MFormInput } from '../molecules/MFormInput.js'
import { MFormCheckboxList } from '../molecules/MFormCheckboxList.js'
import { MCommandPalette } from '../molecules/MCommandPalette.js'

export const OAdminUser = ({
  adminUser,
  adminUsers,
  organizations,
  teams = [],
  updateAdminUser,
  deleteAdminUser,
  isSameAdmin,
  isSuperAdmin,
  availableRoles = ['admin', 'super_admin'],
  allowedFields = {},
  acl,
  onAdminUserDeleted = () => {},
}) => {
  const [isEditable, setIsEditable] = useState(false)
  const [isEditSaving, setIsEditSaving] = useState(false)
  const [isDeleteSaving, setIsDeleteSaving] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [editError, setEditError] = useState()
  const aclKeyArr = Object.keys(adminUser.acl || {})

  const allowedFieldsFull = useMemo(
    () => ({
      active: true,
      firstName: true,
      lastName: true,
      email: true,
      role: true,
      organizations: true,
      teams: true,
      ...allowedFields,
    }),
    [allowedFields]
  )

  const defaultAcl = useMemo(() => {
    const organizationAcl = acl || adminUser?.acl

    const organizationAclToObj = Object.keys(organizationAcl || {}).reduce((aggr, key) => {
      return { ...aggr, [key]: true }
    }, {})

    return organizationAclToObj
  }, [acl, adminUser?.acl])

  const defaultAclTeams = useMemo(() => {
    const organizationAcl = acl || adminUser?.acl

    const teamIds = {}
    for (let orgId in acl) {
      if (acl.hasOwnProperty(orgId)) {
        for (let teamId in acl[orgId]) {
          if (acl[orgId].hasOwnProperty(teamId)) {
            teamIds[teamId] = true
          }
        }
      }
    }

    if (!Object.values(teamIds).length) {
      return null
    }

    return teamIds
  }, [acl, adminUser?.acl])

  const adminOrganizations = useMemo(() => {
    const organizationsIdArray = Object.keys(defaultAcl || {}).filter(key => defaultAcl[key])

    if (organizationsIdArray.length === 0) return []

    return organizationsIdArray
      .map(orgId => organizations.find(org => org.id === orgId))
      .filter(org => org?.id)
  }, [defaultAcl, organizations])

  const adminTeams = useMemo(() => {
    const teamsIdArray = Object.keys(defaultAclTeams || {}).filter(key => defaultAclTeams[key])

    if (teamsIdArray.length === 0) return []

    return teamsIdArray
      .map(teamId => teams.find(team => team.id === teamId))
      .filter(team => team?.id)
  }, [defaultAclTeams, teams])

  const defaultFormValues = useMemo(() => {
    if (!adminUser) return null

    return {
      adminRole: adminUser?.role?.key || 'admin',
      ...adminUser,
      acl: defaultAcl,
      aclTeams: defaultAclTeams,
    }
  }, [adminUser, defaultAcl, defaultAclTeams])

  const {
    control,
    register,
    watch,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: defaultFormValues,
  })

  const aclField = watch('acl')
  const aclTeamsField = watch('aclTeams')
  const adminRoleField = watch('adminRole')

  const isOrganizationSelectionAvailable = useMemo(
    () => adminRoleField === 'admin' || adminRoleField === 'teacher_admin',
    [adminRoleField]
  )
  const isMultipleOrganizationSelection = useMemo(
    () => adminRoleField === 'admin',
    [adminRoleField]
  )
  const isTeamSelectionAvailable = useMemo(() => {
    const aclFieldArray = Object.values(aclField || {}).filter(val => val)

    return !!(adminRoleField === 'teacher_admin' && aclFieldArray.length)
  }, [adminRoleField, aclField])

  const selectedOrganizations = useMemo(() => {
    const aclFieldArray = Object.keys(aclField || {})

    if (!aclFieldArray.length) return []

    return aclFieldArray.map(orgId => organizations.find(org => org.id === orgId))
  }, [aclField, organizations])

  const teamsToSelect = useMemo(() => {
    return teams.filter(team => selectedOrganizations.find(org => org.id === team.organizationId))
  }, [selectedOrganizations, teams])

  const selectedTeams = useMemo(() => {
    const aclTeamsArray = Object.keys(aclTeamsField || {})

    if (!aclTeamsArray.length || !teams?.length) return []

    return aclTeamsArray.map(teamId => teamsToSelect.find(team => team.id === teamId))
  }, [aclTeamsField, teamsToSelect])

  const handleEditButton = useCallback(() => {
    setIsEditable(!isEditable)
  }, [isEditable])

  const handleSubmitEdit = async data => {
    setEditError(null)
    setIsEditSaving(true)

    const isSuperAdmin = data.adminRole === 'super_admin'

    if (!isSuperAdmin && !Object.values(data.acl || {}).filter(val => val).length) {
      setError('acl', {
        type: 'manual',
        message: 'Organization Access must have at least one organization selected',
      })

      return
    }

    try {
      const hasEmailChanged = data.email !== adminUser.email

      if (hasEmailChanged && adminUsers.find(user => user.email === data.email)) {
        throw new Error('User with this email already exists')
      }

      let acl = data.acl
      const aclTeams = data.aclTeams
      const aclTeamsArray = Object.keys(aclTeams || {}).filter(val => val)

      if (aclTeamsArray?.length) {
        let aclOrgWithTeams = {}

        teams.forEach(team => {
          // Check if the organizationId is in acl and teamId is in aclTeams
          if (acl[team.organizationId] && aclTeams[team.id]) {
            // If organizationId not in result, add it
            if (!aclOrgWithTeams[team.organizationId]) {
              aclOrgWithTeams[team.organizationId] = {}
            }

            // Add teamId to the organization
            aclOrgWithTeams[team.organizationId][team.id] = true
          }
        })

        acl = aclOrgWithTeams
      }

      await updateAdminUser({
        id: data.id,
        adminRole: data.adminRole,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        acl: acl,
        isSuperAdmin: isSuperAdmin,
        active: data.active,
      })

      setIsEditable(false)
    } catch (err) {
      setEditError(err?.message)
    }

    setIsEditSaving(false)
  }

  const handleDeleteAdminUser = useCallback(async () => {
    setIsDeleteSaving(true)

    await deleteAdminUser(adminUser.id)
    await onAdminUserDeleted(adminUser.id)

    setIsDeleteSaving(false)
  }, [adminUser.id, deleteAdminUser, onAdminUserDeleted])

  useEffect(() => {
    setShowDeleteConfirmation(false)
  }, [isEditable])

  return (
    <div className="react-web--admin__container">
      <form onSubmit={handleSubmit(handleSubmitEdit)}>
        <div className="bg-white shadow sm:rounded-lg mb-24">
          <div className="sm:flex justify-between px-4 py-6 sm:px-6">
            <div>
              <h3 className="text-base font-semibold leading-7 text-gray-900">Admin Information</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                Personal details and contact information.
              </p>
              {allowedFieldsFull.active && (
                <div className="w-auto inline-block mt-2">
                  {isEditable ? (
                    <Controller
                      name="active"
                      register={register('active')}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => {
                        return (
                          <AFormCheckboxItem
                            id="active"
                            name={'Active'}
                            checked={value}
                            onChange={onChange}
                            reversed
                          />
                        )
                      }}
                    />
                  ) : (
                    <dd className="text-sm text-gray-500 flex flex-row items-center justify-between ">
                      <div
                        className={classnames('w-3 h-3 mr-3 rounded-full', {
                          'bg-active': adminUser.active,
                          'bg-red-400': !adminUser.active,
                        })}
                      />
                      <span>{adminUser.active ? 'Active' : 'Deactivated'}</span>
                    </dd>
                  )}
                </div>
              )}
            </div>
            <div>
              <AButton onClick={handleEditButton} className="mt-2 sm:mt-0">
                {!isEditable ? 'Edit' : 'Cancel Editting'}
              </AButton>
            </div>
          </div>
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              {allowedFieldsFull.firstName && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">First name</dt>

                  {isEditable ? (
                    <MFormInput
                      id="firstName"
                      type="text"
                      name="firstName"
                      errorMessage={errors?.firstName?.message}
                      className="w-full appearance-none block px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      {...register('firstName', {
                        required: {
                          value: true,
                          message: 'This field is required',
                        },
                      })}
                    />
                  ) : (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {adminUser?.firstName}
                    </dd>
                  )}
                </div>
              )}
              {allowedFieldsFull.lastName && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Last name</dt>

                  {isEditable ? (
                    <MFormInput
                      id="lastName"
                      type="text"
                      name="lastName"
                      errorMessage={errors?.lastName?.message}
                      className="w-full appearance-none block px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      {...register('lastName', {
                        required: {
                          value: true,
                          message: 'This field is required',
                        },
                      })}
                    />
                  ) : (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {adminUser?.lastName}
                    </dd>
                  )}
                </div>
              )}
              {allowedFieldsFull.email && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Email address</dt>

                  {isEditable ? (
                    <MFormInput
                      id="email"
                      type="email"
                      name="email"
                      errorMessage={errors?.email?.message}
                      className="w-full appearance-none block px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      {...register('email', {
                        required: 'Invalid email address',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        },
                      })}
                    />
                  ) : (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {adminUser?.email}
                    </dd>
                  )}
                </div>
              )}

              {!!allowedFieldsFull?.organizations && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Organizations</dt>

                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {adminUser?.role?.key === 'super_admin'
                      ? 'All'
                      : adminOrganizations.map(org => org.name).join(', ')}
                  </dd>
                </div>
              )}

              {!!allowedFieldsFull?.teams && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Teams</dt>

                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {adminUser?.role?.key === 'super_admin' || adminUser?.role?.key === 'admin'
                      ? 'All'
                      : adminTeams.map(team => team.name).join(', ')}
                  </dd>
                </div>
              )}

              {allowedFieldsFull.role && (
                <>
                  {' '}
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">Role</dt>

                    {isEditable && !isSameAdmin ? (
                      <Controller
                        name="adminRole"
                        register={register('adminRole')}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => {
                          const roles = {
                            super_admin: { name: 'Super Admin', id: 'super_admin' },
                            admin: {
                              name: 'Organization Admin',
                              id: 'admin',
                            },
                            teacher_admin: {
                              name: 'Teacher Admin',
                              id: 'teacher_admin',
                            },
                          }

                          const defaultValue = roles[getValues().adminRole]

                          return (
                            <MFormSelect
                              items={availableRoles.map(role => roles[role])}
                              className="w-1/2 mt-1"
                              defaultValue={defaultValue}
                              onChange={value => {
                                onChange(value?.id)
                                setValue('acl', null)
                                setValue('aclTeams', null)
                              }}
                            />
                          )
                        }}
                      />
                    ) : (
                      <div className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {adminUser.role?.label}
                      </div>
                    )}

                    {isEditable && isOrganizationSelectionAvailable && (
                      <div className="sm:col-span-4">
                        <div className="block text-xl font-medium leading-6 text-gray-900">
                          Organizations Access
                        </div>
                        <div className="mt-2">
                          <Controller
                            name="acl"
                            register={register('acl', {
                              required: {
                                value: true,
                                message: 'One or more organizations must be selected',
                              },
                            })}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState,
                            }) => (
                              <MCommandPalette
                                items={organizations}
                                selectedItems={selectedOrganizations}
                                selectedItemsLabel={
                                  isMultipleOrganizationSelection
                                    ? 'Selected Organizations'
                                    : 'Selected Organization'
                                }
                                availableItemsLabel={
                                  isMultipleOrganizationSelection
                                    ? 'Available Organizations'
                                    : 'Available Organization'
                                }
                                onChange={org => {
                                  setValue('aclTeams', null)
                                  if (isMultipleOrganizationSelection) {
                                    let currentValue = aclField || {}

                                    const isSelected = !currentValue[org.id]

                                    let changedValue = {
                                      ...currentValue,
                                    }

                                    if (!isSelected && changedValue[org.id]) {
                                      delete changedValue[org.id]
                                    }

                                    if (isSelected) {
                                      changedValue[org.id] = true
                                    }

                                    if (!Object.keys(changedValue).length) {
                                      changedValue = null
                                    }

                                    onChange(changedValue)
                                  } else {
                                    onChange({ [org.id]: true })
                                  }
                                }}
                                searchByKeys={['name']}
                                errorMessage={error?.message}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}

                    {isEditable && isTeamSelectionAvailable && !!teamsToSelect?.length && (
                      <div className="sm:col-span-4">
                        <div className="block text-xl font-medium leading-6 text-gray-900">
                          Teams Access
                        </div>
                        <div className="mt-2">
                          <Controller
                            name="aclTeams"
                            register={register('aclTeams', {
                              required: {
                                value: true,
                                message: 'One or more teams must be selected',
                              },
                            })}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState,
                            }) => (
                              <MCommandPalette
                                items={teamsToSelect}
                                selectedItems={selectedTeams}
                                selectedItemsLabel="Selected Teams"
                                availableItemsLabel="Available Teams"
                                onChange={team => {
                                  let currentValue = aclTeamsField || {}

                                  const isSelected = !currentValue[team.id]

                                  let changedValue = {
                                    ...currentValue,
                                  }

                                  if (!isSelected && changedValue[team.id]) {
                                    delete changedValue[team.id]
                                  }

                                  if (isSelected) {
                                    changedValue[team.id] = true
                                  }

                                  if (!Object.keys(changedValue).length) {
                                    changedValue = null
                                  }

                                  onChange(changedValue)
                                }}
                                searchByKeys={['name']}
                                errorMessage={error?.message}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </dl>

            {isEditable && (
              <div className="p-4">
                <div className="flex justify-end">
                  <div className="flex items-center">
                    {isEditable && isSuperAdmin && !isSameAdmin && (
                      <div className="flex flex-row justify-end p-4">
                        {showDeleteConfirmation ? (
                          <div className="flex items-center space-x-4">
                            <p className="whitespace-nowrap text-sm text-right leading-5 text-gray-600">
                              Are you sure?
                            </p>
                            <AButton
                              secondary
                              onClick={() => {
                                setShowDeleteConfirmation(false)
                              }}
                            >
                              Cancel
                            </AButton>
                            <AButton
                              warning
                              onClick={handleDeleteAdminUser}
                              isLoading={isDeleteSaving}
                              className="whitespace-nowrap"
                            >
                              Yes, delete admin user
                            </AButton>
                          </div>
                        ) : (
                          <AButton
                            warning
                            onClick={() => setShowDeleteConfirmation(true)}
                            className="whitespace-nowrap"
                          >
                            Delete admin user
                          </AButton>
                        )}
                      </div>
                    )}
                    <AButton type="submit" isLoading={isEditSaving}>
                      Save changes
                    </AButton>
                  </div>
                </div>
                {!!editError && (
                  <span className="block text-right text-sm leading-5 font-medium rounded-md text-red-500 mr-4">
                    {editError}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

OAdminUser.propTypes = {
  availableRoles: PropTypes.arrayOf(PropTypes.oneOf(['super_admin', 'admin', 'teacher_admin'])),
  allowedFields: PropTypes.shape({
    active: PropTypes.bool,
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    email: PropTypes.bool,
    role: PropTypes.bool,
  }),
  acl: PropTypes.object,
}
