import React, { useEffect, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { fetchAllContent, fetchFullUserContent } from '@edwin/sdk-admin'

import { OUserTopicWithMissions, OLoader } from '../organisms'
import { isNumber } from 'lodash'

export const OUserProgress = ({ user, viewOnly = false, points }) => {
  const [isUserContentLoading, setIsUserContentLoading] = useState(true)
  const [localAssignedTopics, setLocalAssignedTopics] = useState([])
  const [assignedTopics, setAssignedTopics] = useState([])
  const [fullContent, setFullContent] = useState([])
  const [isFullContentLoading, setIsFullContentLoading] = useState(false)

  const expandableTopicIndex = useMemo(() => {
    const inProgressIndex = localAssignedTopics.findIndex(topic => {
      const completedMissions =
        topic?.missionsAndSeries?.filter(({ progress }) => progress?.isCompleted) || []
      const totalMissions = topic?.missionsAndSeries?.length || 0
      return completedMissions.length > 0 && completedMissions.length < totalMissions
    })

    if (inProgressIndex !== -1) return inProgressIndex

    return localAssignedTopics.findIndex(topic => {
      const completedMissions =
        topic?.missionsAndSeries?.filter(({ progress }) => progress?.isCompleted) || []
      return completedMissions.length === 0
    })
  }, [localAssignedTopics])

  const handleFetchContent = useCallback(async () => {
    setIsFullContentLoading(true)
    const content = await fetchAllContent()
    const userContent = await fetchFullUserContent(user.id)

    setFullContent(content)
    setAssignedTopics(
      userContent.topics
        .filter(userTopic => content?.topics?.find(t => t.id === userTopic.id))
        .map(userTopic => {
          const topicMissions =
            content?.topics?.find(t => t.id === userTopic.id)?.missionsAndSeries || []

          const userTopicMissions = topicMissions
            .map(tms => {
              const userTopicMission = userTopic.missionsAndSeries?.find(ums => ums.id === tms.id)

              const isAssigned = !!userTopicMission

              const mission = isAssigned ? { ...tms, ...userTopicMission } : tms

              return {
                ...mission,
                isAssigned,
              }
            })
            .filter(({ isAssigned }) => isAssigned)

          return {
            ...userTopic,
            missionsAndSeries: userTopicMissions,
          }
        })
    )

    setIsFullContentLoading(false)
  }, [user.id])

  useEffect(() => {
    const asyncFn = async () => {
      setIsUserContentLoading(true)
      await handleFetchContent()
      setIsUserContentLoading(false)
    }

    asyncFn()
  }, [handleFetchContent])

  useEffect(() => {
    if (assignedTopics) {
      setLocalAssignedTopics(assignedTopics)
    }
  }, [assignedTopics, fullContent?.topics, viewOnly])

  if (isFullContentLoading) {
    return (
      <div className="react-web--admin__container" style={{ height: '100%' }}>
        <div className="flex items-center h-full">
          <OLoader className="h-full" />
        </div>
      </div>
    )
  }

  return (
    <div className="react-web--admin__container">
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-9 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            User's program progress
          </h2>
        </div>
      </div>

      {isNumber(points) && (
        <div className="mt-4 py-4 px-4 border border-gray-200 rounded-lg">
          <span className="text-2xl font-semibold">{points}</span>{' '}
          <span className="text-md font-regular">points</span>
        </div>
      )}

      {!!localAssignedTopics.length ? (
        <div className="mt-2 lg:mt-6">
          <div className="grid gap-y-6">
            {localAssignedTopics.map((topic, index) => {
              const isExpanded = index === expandableTopicIndex

              return (
                <OUserTopicWithMissions
                  key={topic.id}
                  index={index}
                  topic={topic}
                  missions={topic?.missionsAndSeries}
                  onToggleMission={() => {}}
                  onAllMissionsChange={() => {}}
                  viewOnly
                  isExpanded={isExpanded}
                />
              )
            })}
          </div>
        </div>
      ) : (
        <span className="inline-block mt-2 text-gray-500 font-light">No topics assigned</span>
      )}
    </div>
  )
}

OUserProgress.propTypes = {
  viewOnly: PropTypes.bool,
  points: PropTypes.number,
}
