import { useCallback, useState, useMemo, useEffect } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { DateTime } from 'luxon'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

import { OSlideOver } from '../organisms/OSlideOver.js'
import { OAddGoal } from '../organisms/OAddGoal.js'
import { OEditGoal } from '../organisms/OEditGoal.js'

import {
  createGoalAndInstancesPayload,
  createEditGoalPayload,
  createUserGoalWithInstances,
  updateAllGoalReminders,
  fetchUserGoals,
  deleteGoal,
  updateGoals,
  fetchAllContent,
  fetchFullUserContent,
} from '@edwin/sdk-admin'

const colors = [
  '#9AC9B9',
  '#B9A8D8',
  '#C29AC9',
  '#8E6BBB',
  '#A8B9D8',
  '#FF9DB5',
  '#FF9D9D',
  '#FF8C6E',
  '#FF6E9D',
  '#6E6EFF',
  '#6E9DFF',
  '#FF8642',
  '#FF4286',
  '#4286FF',
  '#FFC04E',
  '#FF4E69',
]

const CustomTooltip = ({ visible, content, x, y, backgroundColor }) => (
  <div
    style={{
      display: visible ? 'block' : 'none',
      position: 'fixed',
      left: x + 10,
      top: y + 10,
      backgroundColor: backgroundColor || 'rgba(0, 0, 0, 0.85)',
      color: 'white',
      borderRadius: 4,
      padding: '6px 10px',
      fontSize: 12,
      zIndex: 100,
    }}
  >
    {content}
  </div>
)

export const OUserCalendar = ({ user }) => {
  const [selectedDates, setSelectedDays] = useState([])
  const [isEditGoalSlideOverOpen, setIsEditGoalSlideOverOpen] = useState(false)
  const [selectedGoal, setSelectedGoal] = useState({})
  const [isEventsSlideOverOpen, setIsEventsSlideOverOpen] = useState(false)
  const [tooltip, setTooltip] = useState({ visible: false, content: '', x: 0, y: 0 })
  const [deletingGoalEvent, setDeletingGoalEvent] = useState(null)

  const [goals, setGoals] = useState([])
  const [goalInstances, setGoalInstances] = useState([])
  //:TODO: use isUserContentLoading to show loader
  const [isUserContentLoading, setIsUserContentLoading] = useState(true)
  const [assignedUserTopics, setAssignedUserTopics] = useState([])

  const userJoinEvent = useMemo(
    () => ({
      title: 'User joined',
      start: new Date(user.didJoinOn),
      end: new Date(user.didJoinOn),
      allDay: true,
      backgroundColor: '#000',
      borderColor: '#000',
      textColor: '#fff',
    }),
    [user.didJoinOn]
  )

  const handleFetchContent = useCallback(async () => {
    const content = await fetchAllContent()
    const userContent = await fetchFullUserContent(user?.id)

    setAssignedUserTopics(
      userContent.topics
        .filter(userTopic => content?.topics?.find(t => t.id === userTopic.id))
        .map(userTopic => {
          const topicMissions =
            content?.topics?.find(t => t.id === userTopic.id)?.missionsAndSeries || []

          const userTopicMissions = topicMissions.map(tms => {
            const userTopicMission = userTopic.missionsAndSeries?.find(ums => ums.id === tms.id)

            const isAssigned = !!userTopicMission

            const mission = isAssigned ? { ...tms, ...userTopicMission } : tms

            return {
              ...mission,
              isAssigned,
            }
          })

          return {
            ...userTopic,
            missionsAndSeries: userTopicMissions,
          }
        })
        .sort((a, b) => a.priority - b.priority)
    )
  }, [user?.id])

  const handleFetchGoals = useCallback(async () => {
    if (user?.id) {
      try {
        const goalsData = await fetchUserGoals(user.id)
        setGoals(goalsData.goals)
        setGoalInstances(goalsData.goalInstances)
      } catch (err) {
        console.error(err)
      }
    }
  }, [user?.id])

  const handleAddGoalToCalendar = useCallback(
    async data => {
      const goalWithInstances = createGoalAndInstancesPayload(data)

      await createUserGoalWithInstances(goalWithInstances, user.id)

      await handleFetchGoals()
    },
    [handleFetchGoals, user?.id]
  )

  const handleEditGoalInCalendar = useCallback(
    async (goalId, data) => {
      const { reminderData, ...goalData } = data

      const editedGoals = createEditGoalPayload(goalId, goalData)
      await updateGoals(editedGoals)

      if (reminderData) {
        await updateAllGoalReminders(goalId, reminderData)
      }

      await handleFetchGoals()
    },
    [handleFetchGoals]
  )

  const handleDeleteGoalFromCalendar = useCallback(
    async goalId => {
      await deleteGoal(goalId)

      await handleFetchGoals()
    },
    [handleFetchGoals]
  )

  useEffect(() => {
    const asyncFn = async () => {
      setIsUserContentLoading(true)
      await handleFetchContent()
      await handleFetchGoals()
      setIsUserContentLoading(false)
    }
    if (user?.id) {
      asyncFn()
    }
  }, [handleFetchGoals, handleFetchContent, user?.id])

  const missions = useMemo(() => {
    if (!assignedUserTopics?.length) return []

    return assignedUserTopics?.map(topic => topic.missionsAndSeries)?.flat()
  }, [assignedUserTopics])

  const eventsWithColors = useMemo(() => {
    const mergedEvents = goals.map(goal => {
      // Find all goal instances with the same goalId
      const relatedGoalInstances = goalInstances.filter(
        goalInstance => goalInstance.goalId === goal.id
      )

      // Find the earliest start date and the latest end date among the goal instances
      const startDates = relatedGoalInstances.map(goalInstance => new Date(goalInstance.startsOn))
      const endDates = relatedGoalInstances.map(goalInstance => new Date(goalInstance.startsOn))
      const minStartDate = goal?.startsOn
        ? new Date(goal?.startsOn)
        : new Date(Math.min.apply(null, startDates))
      const maxEndDate = goal?.endsOn
        ? new Date(goal?.endsOn)
        : new Date(Math.max.apply(null, endDates))

      const color = colors[goals.indexOf(goal) % colors.length]

      // Create a single event object for the merged goal instances
      return {
        title: goal.title,
        start: minStartDate,
        // Add one day to the end date to display last goal day in calendar proprtionally to the goalInstances days data
        end: DateTime.fromJSDate(maxEndDate).plus({ days: 1 }).toJSDate(),
        allDay: true,
        backgroundColor: color,
        borderColor: color,
        extendedProps: {
          eventColor: color,
          goal,
          goalInstances: relatedGoalInstances,
        },
      }
    })

    const trackedEvents = goalInstances.flatMap(goalInstance => {
      if (!goalInstance.trackedData) return []

      const goal = goals.find(goal => goal.id === goalInstance.goalId)
      const color = colors[goals.indexOf(goal) % colors.length]

      return goalInstance.trackedData.map(trackedItem => {
        let title = goal?.title || ''

        if (goal?.type === 'boolean') {
          title = `${trackedItem.meta?.value === true ? '👍' : '👎 '} ${goal.title}`
        }

        const start = new Date(trackedItem.trackedOn)
        const end = new Date(start)
        end.setMinutes(end.getMinutes() + 1)

        return {
          title,
          start: start,
          end: end,
          allDay: false,
          backgroundColor: color,
          borderColor: color,
          textColor: '#fff',
          extendedProps: {
            eventColor: color,
            instanceGoal: goal,
            goalInstance,
            trackedItem,
          },
        }
      })
    })

    const completedMissionsEvents = missions
      .filter(mission => {
        if (mission.progress?.completedAt) {
          return true
        }

        if (mission.progress?.completedAtDate) {
          return true
        }

        return false
      })
      .map(mission => {
        const color = '#388E3C'

        const start = new Date(mission.progress?.completedAt || mission.progress?.completedAtDate)
        const end = new Date(start)
        end.setMinutes(end.getMinutes() + 1)

        return {
          title: mission.title,
          start,
          end,
          allDay: false,
          backgroundColor: color,
          borderColor: color,
          textColor: '#fff',
          extendedProps: {
            eventColor: color,
            mission,
          },
        }
      })

    const allEvents = userJoinEvent
      ? [...mergedEvents, ...trackedEvents, ...completedMissionsEvents, userJoinEvent]
      : [...mergedEvents, ...trackedEvents, ...completedMissionsEvents]

    return allEvents
  }, [goals, goalInstances, missions, userJoinEvent])

  const handleOpenEventsSlideOver = useCallback(() => {
    setIsEventsSlideOverOpen(true)
  }, [])

  const handleCloseEventsSlideOver = useCallback(() => {
    setIsEventsSlideOverOpen(false)
    setSelectedDays([])
  }, [])

  const handleOpenEditGoalSlideOver = useCallback(() => {
    setIsEditGoalSlideOverOpen(true)
  }, [])

  const handleCloseEditGoalSlideOver = useCallback(() => {
    setIsEditGoalSlideOverOpen(false)
  }, [])

  const handleAddEvent = info => {
    setSelectedDays([
      DateTime.fromJSDate(info.start).startOf('day'),
      // remove one day because full calendar always add 1 day even if only 1 dat is selected
      DateTime.fromJSDate(info.end).minus({ days: 1 }).endOf('day'),
    ])
    handleOpenEventsSlideOver()
  }

  const handleDeleteGoal = async goalId => {
    setDeletingGoalEvent(goalId)

    await handleDeleteGoalFromCalendar(goalId)

    setDeletingGoalEvent(null)
  }

  const handleEventClick = clickInfo => {
    const { extendedProps } = clickInfo.event
    const { goal, goalInstances, goalInstance, instanceGoal, eventColor, mission } = extendedProps

    if (mission) {
      console.log('Clicked event:', { mission })
      return
    }

    if (goal || goalInstances) {
      console.log('Clicked event:', { goal, goalInstances })

      const goalToEdit = {
        ...goal,
        typeMeta: goal.typeMeta,
        type: goal.type,
        id: goal.id,
        title: goal.title,
        prompt: goal.prompt,
        description: goal.description,
        startDate: DateTime.fromMillis(goalInstances[0].startsOn),
        endDate: DateTime.fromMillis(goalInstances[goalInstances.length - 1].endsOn),
        color: eventColor,
      }

      setSelectedGoal(goalToEdit) // Set the selected goal
      handleOpenEditGoalSlideOver() // Open the OEditGoal slide over
    }

    if (goalInstance) {
      console.log('Clicked event:', { goalInstance, goal: instanceGoal })
    }
  }

  const handleAddGoal = async data => {
    await handleAddGoalToCalendar(data)
    handleCloseEventsSlideOver()
  }

  const handleEditGoal = async data => {
    await handleEditGoalInCalendar(selectedGoal.id, data)
    handleCloseEditGoalSlideOver()
  }

  const renderEventContent = eventInfo => {
    const { goal, eventColor, mission } = eventInfo.event.extendedProps

    const isListWeekView = eventInfo.view.type === 'listWeek'

    if (mission) {
      return (
        <div
          className="flex items-center text-white w-full px-2"
          style={{ backgroundColor: eventColor }}
        >
          <span>{eventInfo.timeText}</span>
          <span className="ml-2">{eventInfo.event.title}</span>
        </div>
      )
    }

    if (goal) {
      if (isListWeekView) {
        return (
          <div className="react-web--admin__container">
            <div className="flex flex-row justify-between items-center w-full">
              <div className="flex flex-row items-center">
                <div>{eventInfo.timeText}</div>
                <div className="ml-2">{eventInfo.event.title}</div>
              </div>

              {deletingGoalEvent === goal.id ? (
                <div className="w-4 h-4 ml-2 text-gray-400 cursor-pointer animate-pulse opacity-50">
                  <TrashIcon />
                </div>
              ) : (
                <TrashIcon
                  className="w-4 h-4 ml-2 text-gray-400 cursor-pointer"
                  onClick={() => handleDeleteGoal(goal.id)}
                />
              )}
            </div>
          </div>
        )
      }

      return (
        <div className="react-web--admin__container">
          <div className="flex items-center w-full">
            <div className="flex flex-row items-center">
              <div>{eventInfo.timeText}</div>
              {deletingGoalEvent === goal.id ? (
                <div className="w-4 h-4 ml-2 text-white cursor-pointer animate-pulse opacity-50">
                  <TrashIcon />
                </div>
              ) : (
                <TrashIcon
                  className="w-4 h-4 ml-2 text-white cursor-pointer"
                  onClick={() => handleDeleteGoal(goal.id)}
                />
              )}
            </div>
            <div className="ml-2">{eventInfo.event.title}</div>
          </div>
        </div>
      )
    }

    return (
      <div className="react-web--admin__container">
        <div className="flex flex-col text-ellipsis overflow-hidden">
          <div className="flex flex-row items-center">
            {eventInfo.view.type !== 'listWeek' && (
              <div
                className="rounded-full w-3 h-3 mr-2 flex-shrink-0"
                style={{ backgroundColor: eventColor }}
              />
            )}
            <div>{eventInfo.timeText}</div>
          </div>
          <div className="text-ellipsis overflow-hidden">{eventInfo.event.title}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="react-web--admin__container">
      <div className="lg:flex lg:h-full lg:flex-col">
        <div className="calendar-container">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={eventsWithColors}
            selectable={['range']}
            select={handleAddEvent}
            eventClick={handleEventClick}
            eventContent={renderEventContent}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,listWeek',
            }}
            dayCellClassNames={['group']}
            selectAllow={selectInfo => {
              // Prevent selection if the start date is in the past
              const startDateTime = DateTime.fromISO(selectInfo.startStr).startOf('day')
              return startDateTime >= DateTime.local().startOf('day')
            }}
            eventDidMount={info => {
              // Show the tooltip only for goalInstances events
              // Show the tooltip on mouseover
              info.el.addEventListener('mouseover', e => {
                const isListWeekView = info.view.type === 'listWeek'

                if (!isListWeekView) {
                  setTooltip({
                    visible: true,
                    content: info.event.title,
                    x: e.clientX,
                    y: e.clientY,
                    backgroundColor: info.event.extendedProps.eventColor,
                  })
                }
              })

              // Hide the tooltip on mouseleave
              info.el.addEventListener('mouseleave', () => {
                setTooltip({ visible: false, content: '', x: 0, y: 0, backgroundColor: '' })
              })
            }}
          />
          <CustomTooltip
            visible={tooltip.visible}
            content={tooltip.content}
            x={tooltip.x}
            y={tooltip.y}
            backgroundColor={tooltip.backgroundColor}
          />
        </div>
      </div>

      <OSlideOver
        slideContent={
          <OAddGoal
            startDate={selectedDates[0]}
            endDate={selectedDates[1]}
            onSavingFinished={handleAddGoal}
          />
        }
        isOpen={isEventsSlideOverOpen}
        onClose={handleCloseEventsSlideOver}
      ></OSlideOver>

      <OSlideOver
        slideContent={<OEditGoal {...selectedGoal} onSavingFinished={handleEditGoal} />}
        isOpen={isEditGoalSlideOverOpen}
        onClose={handleCloseEditGoalSlideOver}
      ></OSlideOver>
    </div>
  )
}
