import { Fragment, useState, useMemo, useCallback, useEffect } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export const MCommandPalette = ({
  items,
  selectedItems,
  searchByKeys = ['name'],
  searchLabel = 'Search...',
  errorMessage,
  onChange = () => {},
  selectedItemsLabel = 'Selected Items',
  availableItemsLabel = 'Available Items',
  className,
}) => {
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(true)

  const filteredItems = useMemo(() => {
    const itemsFilteredByChecked = items.filter(item => {
      return !selectedItems.find(selectedItem => selectedItem?.id === item?.id)
    })

    return query === ''
      ? itemsFilteredByChecked
      : itemsFilteredByChecked.filter(item => {
          return searchByKeys.some(key => item[key].toLowerCase().includes(query.toLowerCase()))
        })
  }, [query, items, selectedItems])

  const handleOnChange = useCallback(
    item => {
      onChange(item)
      setQuery('')
    },
    [onChange]
  )

  return (
    <div className="w-full overflow-y-auto">
      <div className="mx-auto transform rounded-xl bg-white p-2ring-1 ring-black ring-opacity-5 transition-all">
        <Combobox onChange={handleOnChange}>
          <Combobox.Input
            className="w-full bg-white relative pl-3 pr-10 py-2 text-left cursor-default sm:text-sm rounded-md border-0 shadow-sm text-gray-900 ring-1 ring-inset focus:ring-1 focus:ring-inset ring-gray-300 focus:ring-accent"
            placeholder={searchLabel}
            onChange={event => setQuery(event.target.value)}
            value={query}
          />

          <div className="max-h-80 mt-2 rounded-md scroll-py-2 overflow-y-auto text-sm text-gray-800 shadow-sm ring-1 ring-inset focus:ring-1 focus:ring-inset ring-gray-300 focus:ring-accent">
            {selectedItems?.length > 0 && (
              <Combobox.Options static>
                <div style={{ padding: '1px' }}>
                  <h2 className="bg-gray-100 px-4 py-2.5 text-xs font-semibold text-gray-900">
                    {selectedItemsLabel}
                  </h2>

                  {selectedItems.map(item => (
                    <Combobox.Option
                      key={item.id}
                      value={item}
                      className={({ active }) =>
                        classnames(
                          'cursor-default select-none rounded-md px-4 py-2',
                          active && 'bg-accent text-white'
                        )
                      }
                    >
                      <div className="flex flex-row items-center">
                        <div className="mr-2 h-4 w-4">
                          <CheckIcon />
                        </div>

                        {item.name}
                      </div>
                    </Combobox.Option>
                  ))}
                </div>
              </Combobox.Options>
            )}

            {filteredItems?.length > 0 && (
              <Combobox.Options static>
                <div style={{ padding: '1px' }}>
                  <h2 className="bg-gray-100 px-4 py-2.5 text-xs font-semibold text-gray-900">
                    {availableItemsLabel}
                  </h2>

                  {filteredItems.map(item => {
                    const isChecked = selectedItems.find(
                      selectedItem => selectedItem.id === item.id
                    )

                    return (
                      <Combobox.Option
                        key={item.id}
                        value={item}
                        className={({ active }) =>
                          classnames(
                            'cursor-default select-none rounded-md px-4 py-2',
                            active && 'bg-accent text-white'
                          )
                        }
                      >
                        <div className="flex flex-row items-center">
                          {isChecked && (
                            <div className="mr-2 h-4 w-4">
                              <CheckIcon />
                            </div>
                          )}

                          {item.name}
                        </div>
                      </Combobox.Option>
                    )
                  })}
                </div>
              </Combobox.Options>
            )}
          </div>

          {query !== '' && filteredItems?.length === 0 && (
            <div className="px-4 py-14 text-center sm:px-14">
              <p className="mt-4 text-sm text-gray-900">No results found using that search term.</p>
            </div>
          )}
        </Combobox>
      </div>

      {errorMessage && (
        <p className="block text-sm font-medium leading-5 text-red-400 mt-1">{errorMessage}</p>
      )}
    </div>
  )
}

MCommandPalette.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  searchByKeys: PropTypes.arrayOf(PropTypes.string),
  searchLabel: PropTypes.string,
  selectedItemsLabel: PropTypes.string,
  availableItemsLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
}
