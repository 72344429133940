import React, { Fragment, useCallback, useMemo, useState, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

export const MDropdown = ({
  items,
  showSelectBoxInput = false,
  onSelect = () => {},
  children,
  containerClassName,
  dropdownClassName,
}) => {
  const [dropdownItems, setDropdownItems] = useState(items)

  useEffect(() => {
    setDropdownItems(items)
  }, [items])

  const activeItem = useMemo(() => {
    return dropdownItems.find(item => item.isActive)
  }, [dropdownItems])

  const handleSelectItem = useCallback(
    async item => {
      setDropdownItems(prevItems => prevItems.map(i => ({ ...i, isActive: i.name === item.name })))

      onSelect(item)
    },
    [onSelect]
  )

  return (
    <div className="react-web--admin__container">
      <Menu
        as="div"
        className={classnames('relative flex justify-end w-full', {
          [containerClassName]: !!containerClassName,
        })}
      >
        {!!showSelectBoxInput && (
          <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-900 shadow-sm px-4 py-2 bg-white text-sm font-medium hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900">
              {activeItem?.name || 'Select'}
              <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
        )}

        {!!children && !showSelectBoxInput && <Menu.Button>{children}</Menu.Button>}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classnames(
              'origin-top-right absolute top-8 right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-gray-900 ring-opacity-5 focus:outline-none z-10',
              {
                [dropdownClassName]: !!dropdownClassName,
              }
            )}
          >
            <div className="py-1">
              {dropdownItems?.map(item => {
                const { name, id, isActive, disabled, customComponent, icon, className } = item

                if (customComponent) {
                  return (
                    <div
                      key={id}
                      className={classnames('block px-4 py-2 text-sm', {
                        [className]: !!className,
                      })}
                    >
                      {customComponent}
                    </div>
                  )
                }

                if (disabled) {
                  return (
                    <div
                      key={id}
                      className={classnames('block px-4 py-2 text-sm', {
                        [className]: !!className,
                      })}
                    >
                      {name}
                    </div>
                  )
                }

                return (
                  <Menu.Item key={id}>
                    <div
                      className={classnames(
                        'flex items-center cursor-pointer px-4 py-2 text-sm hover:bg-gray-500 hover:text-white',
                        {
                          'font-semi': isActive,
                          [className]: !!className,
                        }
                      )}
                      onClick={() => handleSelectItem(item)}
                    >
                      {!!icon &&
                        React.cloneElement(icon, {
                          className: 'mr-2 w-4 h-4 text-inherit text-gray-900',
                        })}
                      <span>{name}</span>
                    </div>
                  </Menu.Item>
                )
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

MDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      disabled: PropTypes.bool,
      customComponent: PropTypes.node,
      className: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  showSelectBoxInput: PropTypes.bool,
  onSelect: PropTypes.func,
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
}
