import React, { useRef, useMemo } from 'react'
import classnames from 'classnames'
import { ABadge } from '../atoms/ABadge'
import { FolderIcon } from '@heroicons/react/20/solid'

export const OAssignTopicWithMissions = ({
  missions,
  topic,
  index,
  onDeleteTopic,
  onToggleMission,
  onAllMissionsChange,
  viewOnly,
}) => {
  const checkbox = useRef()

  const areAllChecked = useMemo(() => {
    return !missions?.some(({ isAssigned }) => !isAssigned)
  }, [missions])

  const topicStatus = useMemo(() => {
    const checkedMissions = missions?.filter(({ isAssigned }) => isAssigned)

    if (checkedMissions?.length === 0) {
      return
    }

    if (!checkedMissions?.find(({ progress }) => !progress?.isCompleted)) {
      return 'Completed'
    }

    if (checkedMissions?.some(({ progress }) => progress?.isCompleted)) {
      return 'In progress'
    }
  }, [missions])

  return (
    <div className="react-web--admin__container">
      <div className="py-8 px-4 border border-gray-200 rounded-lg">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto flex">
            <span className="mr-3 inline-flex rounded-lg p-3 bg-accent bg-opacity-10 ring-4 ring-white">
              <FolderIcon className="h-6 w-6 text-accent" aria-hidden="true" />
            </span>
            <div>
              <h1 className="flex items-center">
                <span className="text-xl font-semibold text-gray-900 leading-6">{topic.name}</span>
                {topicStatus && <ABadge status={topicStatus} className="ml-2" />}
              </h1>
              {!!topic.internalName && (
                <p className="pt-2 text-sm text-gray-500">{topic.internalName}</p>
              )}
            </div>

            {/* <p className="mt-2 text-sm text-gray-700 max-w-prose">{topic.description}</p> */}
          </div>
          {onDeleteTopic && !viewOnly ? (
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                onClick={() => onDeleteTopic(topic.id)}
              >
                Remove
              </button>
            </div>
          ) : null}
        </div>
        <div className="ml-12 mt-6 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead>
                    <tr>
                      {viewOnly ? null : (
                        <th scope="col" className="relative pl-7 sm:w-12 sm:pl-6">
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border border-gray-300 text-accent focus:ring-accent"
                            ref={checkbox}
                            value={areAllChecked}
                            checked={areAllChecked}
                            onChange={() => onAllMissionsChange(topic?.id, !areAllChecked)}
                          />
                        </th>
                      )}
                      <th
                        scope="col"
                        className="min-w-[12rem] py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Assignements
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {missions?.map(tms => (
                      <tr
                        key={tms.id}
                        className={tms.isAssigned && !viewOnly ? 'bg-gray-50' : undefined}
                      >
                        {viewOnly ? null : (
                          <td className="relative pl-7 sm:w-12 sm:pl-6">
                            <>
                              {tms.isAssigned && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-accent" />
                              )}
                              <input
                                type="checkbox"
                                className={classnames(
                                  'absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border border-gray-300 text-accent',
                                  tms?.progress?.isCompleted
                                    ? 'hover:border-gray-300 cursor-not-allowed'
                                    : 'focus:ring-accent cursor-pointer'
                                )}
                                value={tms.isAssigned}
                                checked={tms.isAssigned}
                                disabled={tms?.progress?.isCompleted}
                                onChange={() => onToggleMission(topic?.id, tms.id)}
                              />
                            </>
                          </td>
                        )}
                        <td
                          className={classnames(
                            'py-4 px-3 text-sm font-medium',
                            tms.isAssigned && !viewOnly ? 'text-accent' : 'text-gray-900'
                          )}
                        >
                          {tms.title || tms.name}
                          {tms.progress?.isCompleted ? <ABadge className="ml-1" /> : undefined}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
