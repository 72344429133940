import React from 'react'

export const AFormWrapper = ({ children, title }) => (
  <div className="react-web--admin__container">
    <div className="p-10 rounded-lg ring-1 ring-slate-900/10 bg-gray-50">
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-lg font-bold leading-7 text-gray-900">{title}</h2>
        </div>

        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          {children}
        </div>
      </div>
    </div>
  </div>
)
