import React, { useState, useEffect, useCallback, useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

export const MAccordionItem = ({
  className,
  titleElement,
  isExpanded,
  bodyElement,
  overflowDisabled,
}) => {
  const bodyElementWrapperRef = useRef(null)
  const bodyElementRef = useRef(null)
  const [shouldExpand, setShouldExpand] = useState(isExpanded)
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [shouldDisableOverflow, setShouldDisableOverflow] = useState(false)

  const [bodyHeight, setBodyHeight] = useState(isExpanded ? 'auto' : '0')

  useEffect(() => {
    if (bodyElementRef.current) {
      const height = shouldExpand ? bodyElementRef.current.clientHeight : 0

      setBodyHeight(`${height}px`)
    }
  }, [shouldExpand])

  const handleExpand = useCallback(() => {
    setShouldAnimate(true) // Enable animations

    setShouldExpand(prev => !prev)
  }, [])

  useEffect(() => {
    if (shouldExpand) {
      setTimeout(() => {
        setShouldDisableOverflow(true)
      }, 300)
    } else {
      setShouldDisableOverflow(false)
    }
  }, [shouldExpand])

  return (
    <div className="react-web--admin__container" style={{ width: '100%' }}>
      <div className={classnames('w-full', { [className]: !!className })}>
        <div
          className="flex flex-row items-center justify-between w-full cursor-pointer"
          onClick={handleExpand}
        >
          {!!titleElement && <div className="w-full">{titleElement}</div>}

          <div className="-ml-4 h-5 w-5">
            {shouldExpand ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </div>
        </div>

        <div
          ref={bodyElementWrapperRef}
          className={classnames(' w-full', {
            'overflow-hidden': !overflowDisabled && !shouldDisableOverflow,
            'transition-all duration-300 ease-in-out': shouldAnimate,
          })}
          style={{ height: bodyHeight }}
        >
          <div ref={bodyElementRef}>{bodyElement}</div>
        </div>
      </div>
    </div>
  )
}

MAccordionItem.propTypes = {
  className: PropTypes.string,
  titleElement: PropTypes.node,
  isExpanded: PropTypes.bool,
  bodyElement: PropTypes.node,
  overflowDisabled: PropTypes.bool,
}
