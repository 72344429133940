import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { AButton } from '../atoms/AButton'
import { MCard } from '../molecules/MCard'

// import { useTeamsForOrganization } from '@services/teams'

export const OOrganizationTeams = ({
  organization,
  teams,
  isSuperAdmin,
  onAddGroup = () => {},
}) => {
  //   const handleAddGroup = useCallback(() => {
  //     navigate(generateRoute(ROUTES.ADD_TEAM, { organizationId: organization.id }), {
  //       state: { organization },
  //     })
  //   }, [])

  return (
    <div className="react-web--admin__container">
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {teams?.map((team, index) => (
          <li key={team.id} className="col-span-1">
            <Link
              to={`/organizations/${organization.id}/teams/${team.id}`}
              state={{ organization, team }}
            >
              <MCard title={team.name} className="cursor-pointer">
                <>
                  <p className="mt-1 text-gray-500 text-sm truncate">
                    {team.users.length} active users
                  </p>
                  <p className="mt-1 text-gray-500 text-sm truncate">
                    {team.invitations} pending invitations
                  </p>
                </>
              </MCard>
            </Link>
          </li>
        ))}
      </ul>
      {!Boolean(teams?.length) && (
        <div>
          <span className="mr-8">This organization has no groups yet</span>
          {isSuperAdmin && <AButton onClick={onAddGroup}>Add Group</AButton>}
        </div>
      )}
    </div>
  )
}

OOrganizationTeams.propTypes = {
  organization: PropTypes.object.isRequired,
}
