import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { AFormCheckboxItem } from '../atoms/AFormCheckboxItem.js'
import { AFormInputWrapper } from '../atoms/AFormInputWrapper.js'

export const MFormCheckboxList = ({
  items,
  errorMessage,
  onChange = () => {},
  checkedValues,
  className,
}) => {
  const groupedItems = useMemo(() => {
    const groupedObject = {
      itemsWithouthGroupAssigned: [],
    }

    items.forEach(item => {
      if (item.group && !groupedObject[item.group]) {
        groupedObject[item.group] = []
      }

      if (item.group && groupedObject[item.group]) {
        groupedObject[item.group].push(item)
      } else {
        groupedObject.itemsWithouthGroupAssigned.push(item)
      }
    })

    const itemsGroupedFlat = []

    Object.keys(groupedObject).forEach(key => {
      const groupItems = groupedObject[key]

      if (key !== 'itemsWithouthGroupAssigned') {
        itemsGroupedFlat.push({
          id: key,
          title: key,
          type: 'groupSeparator',
        })
      }

      itemsGroupedFlat.push(...groupItems)
    })

    return itemsGroupedFlat
  }, [items])

  return (
    <div className="react-web--admin__container">
      <AFormInputWrapper errorMessage={errorMessage}>
        <div
          className={classnames('border-b border-gray-200 mt-2 pr-1 max-h-96 overflow-y-auto', {
            [className]: true,
          })}
        >
          <>
            {groupedItems.map((item, index) => {
              const isChecked = checkedValues?.[item.id] || false

              if (item.type === 'groupSeparator') {
                return (
                  <div
                    key={item.id}
                    className="block text-md font-medium leading-6 text-gray-900 my-4"
                    style={{ border: 'none' }}
                  >
                    {item.title}
                  </div>
                )
              }

              return (
                <AFormCheckboxItem
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  checked={isChecked}
                  className={classnames('py-4 border-t border-gray-200', { 'pl-4': !!item.group })}
                  onChange={event => {
                    onChange(item)
                  }}
                />
              )
            })}
          </>
        </div>
      </AFormInputWrapper>
    </div>
  )
}

MFormCheckboxList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, group: PropTypes.string })
  ).isRequired,
  chekedValues: PropTypes.object, // { [item.id]: true/false }
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
}
