import React, { useEffect, useCallback, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { MFormInput } from '../molecules/MFormInput'
import { MFormSelect } from '../molecules/MFormSelect'
import { AButton } from '../atoms/AButton'

export const OTeamOverview = ({ team, isLoading, onUpdateTeam = () => {}, isSuperAdmin }) => {
  const [isEditable, setIsEditable] = useState(false)
  const [isEditSaving, setIsEditSaving] = useState(false)
  const [editError, setEditError] = useState()
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...team },
  })

  const handleEditButton = useCallback(() => {
    setIsEditable(!isEditable)
  }, [isEditable])

  const handleSubmitEdit = async data => {
    setEditError(null)
    setIsEditSaving(true)
    try {
      await onUpdateTeam(data)

      setIsEditable(false)
    } catch (err) {
      setEditError(err?.message)
    }

    setIsEditSaving(false)
  }

  useEffect(() => {
    reset()
  }, [isEditable, reset])

  return (
    <div className="react-web--admin__container">
      <div className="pb-24">
        <form onSubmit={handleSubmit(handleSubmitEdit)}>
          <div className="overflow-hidden">
            <div className="flex flex-row items-center">
              {isSuperAdmin && (
                <div className="flex flex-row items-center">
                  <AButton onClick={handleEditButton} secondary>
                    {!isEditable ? 'Edit' : 'Cancel Editting'}
                  </AButton>
                </div>
              )}
            </div>
            <div className="py-5">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>

                  {isEditable ? (
                    <MFormInput
                      id="name"
                      type="text"
                      name="name"
                      errorMessage={errors?.name?.message}
                      {...register('name', {
                        required: {
                          value: true,
                          message: 'This field is required',
                        },
                      })}
                    />
                  ) : (
                    <dd className="mt-1 text-sm text-gray-900">{team?.name}</dd>
                  )}
                </div>

                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Leaderboards</dt>

                  {isEditable ? (
                    <Controller
                      name="isLeaderboardAvailable"
                      register={register('isLeaderboardAvailable')}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => {
                        const values = {
                          allowed: { name: 'Allowed', id: 'allowed' },
                          notAllowed: { name: 'Not allowed', id: 'not-allowed' },
                        }

                        return (
                          <MFormSelect
                            items={[values.allowed, values.notAllowed]}
                            className="w-1/2 mt-1"
                            defaultValue={value ? values.allowed : values.notAllowed}
                            onChange={value => {
                              onChange(value?.id === 'allowed')
                            }}
                          />
                        )
                      }}
                    />
                  ) : (
                    <div className="mt-1 text-sm text-gray-900">
                      {team.isLeaderboardAvailable ? 'Allowed' : 'Not allowed'}
                    </div>
                  )}
                </div>
              </dl>
            </div>

            {isEditable && (
              <div className="flex flex-row justify-end w-full p-4">
                <div className="flex flex-row items-center">
                  {!!editError && (
                    <span className="text-sm leading-5 font-medium rounded-md text-red-500 mr-4">
                      {editError}
                    </span>
                  )}
                  <AButton type="submit" isLoading={isEditSaving}>
                    Save changes
                  </AButton>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
