import React from 'react'
import classnames from 'classnames'

import { FolderIcon, PlusCircleIcon } from '@heroicons/react/20/solid'

export const MGridItem = ({ title, description, internalName, numberOfAssignments, onClick }) => (
  <div
    onClick={onClick}
    className={classnames(
      'group relative bg-white p-6 rounded-lg focus-within:ring-2 focus-within:ring-inset focus-within:ring-accent',
      onClick && 'cursor-pointer'
    )}
  >
    <div>
      <span className="inline-flex rounded-lg p-3 bg-accent bg-opacity-10 ring-4 ring-white">
        <FolderIcon className="h-6 w-6 text-accent" aria-hidden="true" />
      </span>
    </div>
    <div className="mt-8">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        <span className="absolute inset-0" aria-hidden="true" />
        {title}
      </h3>

      {!!internalName && <p className="text-gray-500 mb-2 text-sm font-light">{internalName}</p>}

      {numberOfAssignments && (
        <span className="mt-2 text-gray-500 text-sm font-light">{numberOfAssignments}</span>
      )}
      <p className="mt-2 text-sm text-gray-500">{description}</p>
    </div>
    <span
      className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
      aria-hidden="true"
    >
      <PlusCircleIcon className="h-6 w-6" />
    </span>
  </div>
)
