import React from 'react'
import PropTypes from 'prop-types'

export const AFormInputWrapper = ({ label, id, errorMessage, children, labelClassName }) => {
  return (
    <div className="react-web--admin__container">
      <div>
        {!!label && (
          <label
            htmlFor={id}
            className={`mb-2 block text-sm font-medium leading-6 text-gray-900 ${labelClassName}`}
          >
            {label}
          </label>
        )}

        {children}

        {errorMessage && (
          <p className="block text-sm font-medium leading-5 text-red-400 mt-1">{errorMessage}</p>
        )}
      </div>
    </div>
  )
}

AFormInputWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  errorMessage: PropTypes.string,
}
