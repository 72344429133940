import React, { forwardRef, useState, useRef, useLayoutEffect, useEffect, useMemo } from 'react'
import classnames from 'classnames'
import { TrashIcon } from '@heroicons/react/20/solid'

import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { ABadge } from '../atoms/ABadge'

export const MAssignTopicListItem = forwardRef(
  (
    {
      topic = {},
      index,
      name,
      onToggleMission,
      isExpanded,
      isDragDisabled,
      isDragged,
      onDeleteTopic,
      expandedTopicId,
      viewOnly,
      ...props
    },
    ref
  ) => {
    const [contentContainerHeight, setContentContainerHeight] = useState()
    const contentHeightRef = useRef(null)
    const [isFirstHeightSet, setIsFirstHeightSet] = useState(true)

    const numberOfAssignedMissions = useMemo(() => {
      if (!topic?.missionsAndSeries?.length) return 0

      return topic.missionsAndSeries?.filter(mission => mission.isAssigned)?.length
    }, [topic?.missionsAndSeries])

    const topicStatus = useMemo(() => {
      const checkedMissions = topic?.missionsAndSeries?.filter(({ isAssigned }) => isAssigned)

      if (checkedMissions?.length === 0) {
        return
      }

      if (!checkedMissions?.find(({ progress }) => !progress?.isCompleted)) {
        return 'Completed'
      }

      if (checkedMissions?.some(({ progress }) => progress?.isCompleted)) {
        return 'In progress'
      }
    }, [topic.missionsAndSeries])

    useLayoutEffect(() => {
      setContentContainerHeight(
        isExpanded ? contentHeightRef.current?.getBoundingClientRect()?.height : 0
      )
    }, [isExpanded])

    useEffect(() => {
      if (contentContainerHeight !== null) {
        setIsFirstHeightSet(false)
      }
    }, [contentContainerHeight])

    return (
      <div className="react-web--admin__container">
        <div ref={ref} {...props} className="flex items-center gap-x-6 py-3 select-none">
          <div className="flex flex-none items-center text-accent">
            <EllipsisVerticalIcon className="h-5 w-5 -mr-2.5" aria-hidden="true" />
            <EllipsisVerticalIcon className="h-5 w-5 -ml-1" aria-hidden="true" />
          </div>
          <div className="min-w-0 py-2 bg-white">
            <div className="flex items-start gap-x-3">
              <p className="text-sm font-semibold leading-6 text-gray-900">{topic.name}</p>
              {topicStatus && <ABadge status={topicStatus} className="ml-2" />}
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">{numberOfAssignedMissions} assignments</p>
            </div>
          </div>
          {onDeleteTopic ? (
            <div className="ml-auto flex flex-row items center">
              <div
                className="inline-flex w-auto ml-4 flex-row items-center cursor-pointer border-b border-transparent bg-gray-100 p-2 rounded-md"
                onClick={() => onDeleteTopic(topic.id)}
              >
                <TrashIcon size={2} className="text-gray-500 w-4 h-4" />
              </div>
            </div>
          ) : null}
        </div>

        {expandedTopicId === topic.id && (
          <div className="pl-10 pb-2">
            <table
              onClick={e => {
                if (viewOnly) return
                e.preventDefault()
                e.stopPropagation()
              }}
              className="min-w-full table-fixed divide-y divide-gray-300"
            >
              <tbody className="divide-y divide-gray-200 bg-white">
                {topic?.missionsAndSeries?.length &&
                  topic?.missionsAndSeries.map(mission => (
                    <tr key={mission.id} className={mission.isAssigned ? 'bg-gray-50' : undefined}>
                      <td className="relative px-7 sm:w-12 sm:px-6">
                        {mission.isAssigned && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-accent" />
                        )}
                        <input
                          type="checkbox"
                          className={classnames(
                            'absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border border-gray-300 text-accent',
                            mission?.progress?.isCompleted
                              ? 'hover:border-gray-300 cursor-not-allowed'
                              : 'focus:ring-accent cursor-pointer'
                          )}
                          value={mission.isAssigned}
                          checked={mission.isAssigned}
                          disabled={mission?.progress?.isCompleted}
                          onChange={() => {
                            onToggleMission(topic?.id, mission.id)
                          }}
                        />
                      </td>
                      <td
                        className={classnames(
                          'py-4 pr-3 text-sm font-medium',
                          mission.isAssigned ? 'text-accent' : 'text-gray-900'
                        )}
                      >
                        {mission.title || mission.name}
                        {mission.progress?.isCompleted ? <ABadge className="ml-1" /> : undefined}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
)
