import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { DateTime, Interval } from 'luxon'
import {
  Award,
  Target,
  UserCheck,
  PlayCircle,
  PauseCircle,
  ThumbsUp,
  ThumbsDown,
} from 'react-feather'

import { fetchFullUserContent, fetchAllContent, fetchUserGoals, sortTopicsArray } from '@edwin/sdk-admin'

function getValuesFromGoalInstance(goalInstance, goal) {
  if (!goalInstance.isTracked) {
    return 'Not tracked'
  }
  if (goal?.type === 'boolean') {
    const trueLabel = goal.typeMeta?.trueLabel || 'Done'
    const falseLabel = goal.typeMeta?.falseLabel || 'Not done' // Corrected the typo here
    return goalInstance.trackedData[0].meta.value ? trueLabel : falseLabel
  } else {
    return goalInstance.trackedData[0].meta.value
  }
}

const useMergedTimelineData = (user, goals, goalInstances, missions) => {
  return useMemo(() => {
    // Merge and process the data to fit the timeline format
    const mergedData = [
      {
        type: 'user-joined',
        actionString: `User joined`,
        title: `SIGNUP`,
        date: user.didJoinOn ? DateTime.fromMillis(user.didJoinOn) : null,
        timeString: user.didJoinOn
          ? DateTime.fromMillis(user.didJoinOn)?.toLocaleString(DateTime.TIME_SIMPLE)
          : '',
      },
      ...goals.map(goal => ({
        ...goal,
        type: 'goal-started',
        actionString: `Goal started`,
        title: `${goal.title}`,
        date: goal.startsOn ? DateTime.fromMillis(goal.startsOn) : null,
        timeString: goal.startsOn
          ? DateTime.fromMillis(goal.startsOn)?.toLocaleString(DateTime.TIME_SIMPLE)
          : '',
      })),

      ...goals.map(goal => ({
        ...goal,
        type: 'goal-finished',
        actionString: `Goal finished`,
        title: `${goal.title}`,
        date: goal.endsOn ? DateTime.fromMillis(goal.endsOn) : null,
        timeString: goal.endsOn
          ? DateTime.fromMillis(goal.endsOn)?.toLocaleString(DateTime.TIME_SIMPLE)
          : '',
      })),

      ...goalInstances.map(goalInstance => {
        const relatedGoal = goals.find(goal => goal.id === goalInstance.goalId)
        const goalTitle = relatedGoal ? relatedGoal.title : 'Unknown Goal'
        let title = getValuesFromGoalInstance(goalInstance, relatedGoal)
        return {
          ...goalInstance,
          type: 'goalInstance',
          actionString: `${goalTitle}`,
          title: `${title}`,
          date: goalInstance.lastTrackedOn ? DateTime.fromMillis(goalInstance.lastTrackedOn) : null,
          timeString: goalInstance.lastTrackedOn
            ? DateTime.fromMillis(goalInstance.lastTrackedOn)?.toLocaleString(DateTime.TIME_SIMPLE)
            : '',
        }
      }),

      ...missions.map(mission => ({
        ...mission,
        type: 'mission',
        actionString: `Finished mission`,
        title: mission.title || mission.name,
        date: mission?.progress?.completedAt
          ? DateTime.fromMillis(mission.progress.completedAt)
          : null,
        timeString: mission?.progress?.completedAt
          ? DateTime.fromMillis(mission?.progress?.completedAt)?.toLocaleString(
              DateTime.TIME_SIMPLE
            )
          : '',
      })),
    ]

    // Filter out events with a null date and sort the remaining events chronologically
    const sortedData = mergedData
      .filter(event => event.date !== null)
      .sort((a, b) => a.date - b.date)

    return sortedData
  }, [user.didJoinOn, goals, goalInstances, missions])
}

const groupEventsByWeek = (events, didJoinOn) => {
  const weeksSinceJoined = Math.ceil(
    DateTime.fromMillis(didJoinOn).diffNow('weeks').toObject().weeks * -1
  )

  const weekIntervals = Array.from({ length: weeksSinceJoined }, (_, index) => {
    const startOfWeek = DateTime.fromMillis(didJoinOn).startOf('week').plus({ weeks: index })
    const endOfWeek = startOfWeek.endOf('week')
    return Interval.fromDateTimes(startOfWeek, endOfWeek)
  })

  return weekIntervals.map((weekInterval, index) => {
    const weekEvents = events.filter(event => weekInterval.contains(event.date))
    return {
      week: index + 1,
      events: weekEvents,
    }
  })
}

const getIconForEventType = (eventType, relatedGoal, eventValue) => {
  switch (eventType) {
    case 'user-joined':
      return { icon: <UserCheck className="h-5 w-5 text-white" />, color: '#8B10D0' } // Vibrant purple
    case 'goal-started':
      return { icon: <PlayCircle className="h-5 w-5 text-white" />, color: '#4CAF50' } // Slightly darker green
    case 'goal-finished':
      return { icon: <PauseCircle className="h-5 w-5 text-white" />, color: '#D32F2F' } // Darker red
    case 'goalInstance':
      if (relatedGoal?.type === 'boolean') {
        return eventValue
          ? { icon: <ThumbsUp className="h-5 w-5 text-white" />, color: '#388E3C' } // Darker green
          : { icon: <ThumbsDown className="h-5 w-5 text-white" />, color: '#F57C00' } // Darker orange
      } else {
        return { icon: <Award className="h-5 w-5 text-white" />, color: '#2196F3' } // Original blue color
      }
    case 'mission':
      return { icon: <Target className="h-5 w-5 text-white" />, color: '#1976D2' } // Deeper blue
    default:
      return null
  }
}

const groupEventsByDay = (events, joinDate) => {
  const daysWithData = events.reduce((acc, event) => {
    const eventDate = event.date.startOf('day').toMillis()
    if (!acc[eventDate]) {
      acc[eventDate] = {
        date: event.date.startOf('day'),
        events: [],
      }
    }
    acc[eventDate].events.push(event)
    return acc
  }, {})

  const today = DateTime.local().startOf('day')
  const daysCount = Math.ceil(today.diff(joinDate.startOf('day'), 'days').days) + 1

  const dailyTimelineData = []

  for (let i = 0; i < daysCount; i++) {
    const day = joinDate.plus({ days: i }).startOf('day')
    const dayMillis = day.toMillis()

    if (daysWithData[dayMillis]) {
      dailyTimelineData.push(daysWithData[dayMillis])
    } else {
      dailyTimelineData.push({
        date: day,
        events: [],
      })
    }
  }

  return dailyTimelineData
}

const isEventRelatedToGoal = (event, goalId) => {
  if (!goalId) return false
  return (
    (event.type === 'goal-started' && event.id === goalId) ||
    (event.type === 'goal-finished' && event.id === goalId) ||
    (event.type === 'goalInstance' && event.goalId === goalId)
  )
}

const pushNoEventsDays = (noEventsDays, dayElements, dayIndex) => {
  if (noEventsDays.length === 0) return dayElements

  dayElements.push(
    <li key={`dots-missingDays-${dayIndex}`}>
      <div className="relative pb-8">
        <div className="relative flex items-start space-x-3">
          <div className="relative px-1">
            <div
              className="flex flex-grow h-8 w-8 items-center justify-center relative"
              style={{
                top: (noEventsDays.length - 1) * 4,
              }}
            >
              {Array.from({ length: noEventsDays.length }, (_, i) => (
                <div
                  key={`dot-${i}`}
                  className="bg-gray-200 rounded-full"
                  style={{
                    position: 'absolute',
                    top: -i * 4,
                    width: '1.25rem',
                    height: '1.25rem',
                    border: '2px solid white',
                  }}
                />
              ))}
            </div>
          </div>
          <div className="min-w-0 flex-1 py-1.5">
            <div className="text-sm font-normal">
              {noEventsDays.length} day{noEventsDays.length > 1 ? 's' : ''} with no events
            </div>
          </div>
        </div>
      </div>
    </li>
  )

  return dayElements
}

export const OUserTimeline = ({ user }) => {
  const [activeGoalId, setActiveGoalId] = useState(null)
  const [clickedGoalId, setClickedGoalId] = useState(null)
  const [goals, setGoals] = useState([])
  const [goalInstances, setGoalInstances] = useState([])
  const [assignedUserTopics, setAssignedUserTopics] = useState([])
  const [isUserContentLoading, setIsUserContentLoading] = useState(true)

  const missions = useMemo(() => {
    if (!assignedUserTopics?.length) return []

    return assignedUserTopics?.map(topic => topic.missionsAndSeries)?.flat()
  }, [assignedUserTopics])

  const handleFetchContent = useCallback(async () => {
    if (!user?.id) return

    const content = await fetchAllContent()
    const userContent = await fetchFullUserContent(user.id)

    setAssignedUserTopics(
      userContent.topics
        .filter(userTopic => content?.topics?.find(t => t.id === userTopic.id))
        .map(userTopic => {
          const topicMissions =
            content?.topics?.find(t => t.id === userTopic.id)?.missionsAndSeries || []

          const userTopicMissions = topicMissions.map(tms => {
            const userTopicMission = userTopic.missionsAndSeries?.find(ums => ums.id === tms.id)

            const isAssigned = !!userTopicMission

            const mission = isAssigned ? { ...tms, ...userTopicMission } : tms

            return {
              ...mission,
              isAssigned,
            }
          })

          return {
            ...userTopic,
            missionsAndSeries: userTopicMissions,
          }
        })
        .sort(sortTopicsArray)
    )
  }, [user?.id])

  const handleFetchGoals = useCallback(async () => {
    if (!user?.id) return

    try {
      const goalsData = await fetchUserGoals(user.id)
      setGoals(goalsData.goals)
      setGoalInstances(goalsData.goalInstances)
    } catch (err) {
      console.error(err)
    }
  }, [user?.id])

  useEffect(() => {
    const asyncFn = async () => {
      setIsUserContentLoading(true)
      await handleFetchContent()
      await handleFetchGoals()
      setIsUserContentLoading(false)
    }
    asyncFn()
  }, [handleFetchGoals, handleFetchContent])

  const sortedTimelineData = useMergedTimelineData(user, goals, goalInstances, missions)
  const joinDate = DateTime.fromMillis(user.didJoinOn)
  const dailyTimelineData = useMemo(
    () => groupEventsByDay(sortedTimelineData, joinDate),
    [sortedTimelineData, joinDate]
  )

  const dayElements = useMemo(() => {
    let dayElements = []
    let noEventsDays = []

    dailyTimelineData.forEach((dayData, dayIndex) => {
      if (dayData.events.length === 0) {
        noEventsDays.push(dayData.date)
        return
      }

      dayElements = pushNoEventsDays(noEventsDays, dayElements, dayIndex)
      noEventsDays = []

      dayElements.push(
        <li key={`day-${dayIndex}`}>
          <div className="text-sm font-bold pb-4">
            {dayData.date.toLocaleString(DateTime.DATE_FULL)}
          </div>
        </li>
      )
      dayData.events.forEach(event => {
        const eventValue =
          event.type === 'goalInstance' && event.trackedData && event.trackedData[0].meta.value
        const relatedGoal = goals.find(goal => goal.id === event.goalId)
        const { icon, color } = getIconForEventType(event.type, relatedGoal, eventValue)
        const isRelatedToActiveGoal = isEventRelatedToGoal(event, activeGoalId)
        const isRelatedToClickedGoal = isEventRelatedToGoal(event, clickedGoalId)

        dayElements.push(
          <li
            key={`${event.id}-${event.type}`}
            onMouseEnter={() => !clickedGoalId && setActiveGoalId(event.goalId)}
            onMouseLeave={() => !clickedGoalId && setActiveGoalId(null)}
            onClick={() =>
              setClickedGoalId(prevClickedGoalId =>
                prevClickedGoalId === event.goalId ? null : event.goalId
              )
            }
            className={`transition duration-300 ease-in-out ${
              isRelatedToActiveGoal || isRelatedToClickedGoal ? 'scale-110' : 'scale-100'
            }`}
          >
            <div
              className={`relative pb-8 transition duration-300 ease-in-out${
                (activeGoalId || clickedGoalId) && !isRelatedToActiveGoal && !isRelatedToClickedGoal
                  ? ' transition duration-300 ease-in-out grayscale opacity-20 scale-75'
                  : 'scale-100'
              }`}
            >
              <div className="relative flex items-start space-x-3">
                <div className="relative px-1">
                  <div
                    className="flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white"
                    style={{ backgroundColor: color }}
                  >
                    {icon}
                  </div>
                </div>
                <div className="min-w-0 flex-1 py-1.5">
                  <p className="text-sm text-gray-500">{event.actionString || ''}</p>
                  <div className="text-sm">
                    <span className="font-medium text-gray-900">{event.title}</span>
                  </div>
                  <p className="mt-0.5 text-sm text-gray-500">{event.timeString}</p>
                </div>
              </div>
            </div>
          </li>
        )
      })
    })

    dayElements = pushNoEventsDays(noEventsDays, dayElements)

    return dayElements
  }, [dailyTimelineData, goals, activeGoalId, clickedGoalId])

  return (
    <div className="react-web--admin__container" style={{ height: '100%' }}>
      <div className="flex flex-grow items-center justify-center h-full">
        <div className="flow-root">
          <ul className="-mb-8">{dayElements}</ul>
        </div>
      </div>
    </div>
  )
}
