import React, { useState, Fragment } from 'react'

import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

import { AFormInputWrapper } from '../atoms/AFormInputWrapper'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const MFormSelect = ({
  items,
  label,
  errorMessage,
  id,
  name,
  onChange = () => {},
  defaultValue,
  value,
  placeholder = 'Select',
  title,
  className,
  labelClassName,
}) => {
  const [selected, setSelected] = useState(defaultValue)

  return (
    <div className="react-web--admin__container">
      <AFormInputWrapper
        labelClassName={labelClassName}
        label={label}
        errorMessage={errorMessage}
        id={id}
        name={name}
      >
        <Listbox
          value={selected}
          onChange={selected => {
            setSelected(selected)
            onChange(selected)
          }}
        >
          {({ open }) => (
            <div>
              {!!title && (
                <Listbox.Label className="block text-sm font-medium text-gray-700">
                  {title}
                </Listbox.Label>
              )}
              <div className="mt-1 relative">
                <Listbox.Button className="bg-white relative w-full pl-3 pr-10 py-2 text-left cursor-default sm:text-sm rounded-md border-0 shadow-sm text-gray-900 ring-1 ring-inset focus:ring-1 focus:ring-inset ring-gray-300 focus:ring-accent">
                  <span className="block truncate">
                    {selected?.name ? selected.name : placeholder}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {items.map(item => (
                      <Listbox.Option
                        key={item.id}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-gray-500' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                        value={item}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {item.name || item.title}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white bg-gray-500' : 'text-gray-900',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
          )}
        </Listbox>
      </AFormInputWrapper>
    </div>
  )
}
