import CONFIG from '@config/Config'
import { ADMIN_ACCESS_TOKEN } from '@const/LocalStorage'

import { createAxiosInstance } from '@edwin/sdk-admin'

export const instance = createAxiosInstance({
  config: CONFIG,
  adminTokenLocalStorageKey: ADMIN_ACCESS_TOKEN,
})

export default instance
