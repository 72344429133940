import React from 'react'
import { Frown } from 'react-feather'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export const OError = ({
  errorMessage = 'Ups, something went wrong!',
  className,
  errorMessageClassName,
  iconClassName,
}) => {
  return (
    <div className="react-web--admin__container">
      <div
        className={classnames(
          'flex flex-1 flex-col items-center justify-center h-full w-full border',
          {
            [className]: !!className,
          }
        )}
      >
        <div className="flex flex-col items-center">
          <Frown className={classnames('w-12 h-12', { [iconClassName]: !!iconClassName })} />

          <p
            className={classnames('mt-4 text-md font-regular', {
              [errorMessageClassName]: !errorMessageClassName,
            })}
          >
            {errorMessage}
          </p>
        </div>
      </div>
    </div>
  )
}

OError.propTypes = {
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  errorMessageClassName: PropTypes.string,
}
