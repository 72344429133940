import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Grid } from 'react-loader-spinner'

export const OLoader = ({ height = 60, width = 60, className }) => {
  return (
    <div className="react-web--admin__container">
      <div
        className={classnames(
          'flex flex-grow flex-1 mx-auto items-center justify-center fill-accent',
          {
            [className]: true,
          }
        )}
      >
        <Grid height={height} width={width} color="current" />
      </div>
    </div>
  )
}

OLoader.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
}
