import React from 'react'
import classnames from 'classnames'

import { MCard } from './MCard.js'

import { CheckCircleIcon } from '@heroicons/react/20/solid'

export const MAssignMissionListItem = ({ mission, isAssigned, classname, onToggle, viewOnly }) => {
  return (
    <div className="react-web--admin__container">
      <MCard
        className={classnames('transition ease-in duration-100', {
          [classname]: !!classname,
          'opacity-50 drop-shadow-sm transform-gpu': !isAssigned,
          'opacity-100': isAssigned,
          'cursor-default': viewOnly,
          'pointer-events-none': viewOnly,
        })}
        onClick={e => {
          if (viewOnly) return
          e.preventDefault()
          e.stopPropagation()
          onToggle(mission.id)
        }}
      >
        <div className="-ml-4 -mt-4 flex items-center justify-between flex-wrap sm:flex-no-wrap">
          <div className="ml-4 mt-2 flex items-center justify-center w-full">
            <h3 className="text-xl font-semi w-full">{mission.title || mission.name}</h3>
            <CheckCircleIcon
              className={classnames(
                !isAssigned ? 'opacity-0' : 'opacity-100',
                'h-5 w-5 ml-3 transition ease-in duration-100 text-gray-900'
              )}
              aria-hidden="true"
            />
          </div>
        </div>

        {!!mission.description && (
          <div className="mt-4">
            <span>{mission.description}</span>
          </div>
        )}
      </MCard>
    </div>
  )
}
