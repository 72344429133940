import { useMemo } from 'react'
import { doc, addDoc, collection, updateDoc } from 'firebase/firestore'
import { getAxiosInstance } from './axios.js'
import {
  auth,
  firestoreDb,
  COLLECTIONS,
  doesTeamExistsFbFunction,
  retrieveTeamLeaderboards,
  archiveTeamFunction,
} from './firebase.js'

import { useStore } from '../stores/createStore.js'

export const useUsersForTeam = teamId => {
  const { usersRaw, isUsersLoading, teams, organizations } = useStore(state => ({
    usersRaw: state.users,
    isUsersLoading: state.isUsersLoading,
    teams: state.teams,
    organizations: state.organizations,
  }))

  const users = usersRaw
    ? usersRaw
        .filter(user => user.teamId === teamId)
        .map(data => {
          const detailsTmp = data.email

          const team = teams.find(team => team.id === data.teamId)
          const organization = organizations.find(
            organization => organization.id === data.organizationId
          )

          return { ...data, team, organization, details: detailsTmp }
        })
    : null

  return [users, isUsersLoading]
}

export const useUsersForTeams = teams => {
  const { usersRaw, isUsersLoading } = useStore(state => ({
    usersRaw: state.users,
    isUsersLoading: state.isUsersLoading,
  }))

  const users = usersRaw
    ? usersRaw.filter(user => teams.find(team => team.id === user.teamId))
    : null

  return [users, isUsersLoading]
}

export const useArchivedUsersForTeam = teamId => {
  const { archivedUserRaw, isArchivedUsersLoading, teams, organizations } = useStore(state => ({
    archivedUserRaw: state.archivedUsers,
    isArchivedUsersLoading: state.isArchivedUsersLoading,
    teams: state.teams,
    organizations: state.organizations,
  }))

  const archivedUsers = archivedUserRaw
    ? archivedUserRaw
        .filter(user => user.teamId === teamId)
        .map(data => {
          const detailsTmp = data.email

          const team = teams.find(team => team.id === data.teamId)
          const organization = organizations.find(
            organization => organization.id === data.organizationId
          )

          return { ...data, team, organization, details: detailsTmp, active: false }
        })
    : null

  return [archivedUsers, isArchivedUsersLoading]
}

export const useTeamsForOrganization = organizationId => {
  const { teamsRaw, isTeamsLoading } = useStore(state => ({
    teamsRaw: state.teams,
    isTeamsLoading: state.isTeamsLoading,
  }))
  const [users, isUsersLoading] = useUsersForTeams(teamsRaw)

  const isLoading = isTeamsLoading || isUsersLoading

  const teams =
    teamsRaw && users
      ? teamsRaw
          .filter(team => team.organizationId === organizationId)
          .map(team => {
            const id = team.id

            const teamUsers = users.filter(user => user.teamId === id)
            const invitations = teamUsers.filter(user => !user.didJoin).length

            return { ...team, id, users: teamUsers, invitations }
          })
      : null

  return [teams, isLoading]
}

export const useTeam = teamId => {
  const { teamsRaw, isTeamsLoading } = useStore(state => ({
    teamsRaw: state.teams,
    isTeamsLoading: state.isTeamsLoading,
  }))

  const team = useMemo(() => {
    return teamsRaw.find(team => team.id === teamId)
  }, [teamId, teamsRaw])

  return [team, isTeamsLoading]
}

export const addTeam = async teamData => {
  const { name, organizationId } = teamData

  if (!teamData) throw new Error('No team specified')
  if (!organizationId) throw new Error('No organizationId for team specified')
  if (!organizationId) throw new Error('No name for team specified')

  const result = await doesTeamExistsFbFunction({ name, organizationId })
  const { teamExists } = result.data

  if (!teamExists) {
    const teamRef = collection(firestoreDb, COLLECTIONS.teams)

    await addDoc(teamRef, {
      ...teamData,
      createdBy: auth.currentUser.uid,
    })
  } else {
    throw new Error('Team already exists')
  }
}

export const updateTeamTopics = async (teamId, usersIds, availableTopics = []) => {
  if (!teamId) {
    throw new Error('No teamId specified')
  }

  const teamRef = doc(firestoreDb, COLLECTIONS.teams, teamId)

  await updateDoc(teamRef, { availableTopics })

  if (usersIds?.length) {
    const topicsToEdwin = availableTopics
      .map(topic => ({
        ...topic,
        missionsAndSeries: topic?.missionsAndSeries?.length
          ? topic.missionsAndSeries.filter(tms => tms.isAssigned !== false)
          : null,
      }))
      .filter(topic => topic.missionsAndSeries?.length)

    await getAxiosInstance().post('/admin/users-assign-content', {
      users: usersIds,
      topics: topicsToEdwin,
    })
  }
}

export const updateTeamOnboardingMission = async (teamId, usersIds, onboardingMissionId) => {
  if (!teamId) {
    throw new Error('No teamId specified')
  }

  if (!onboardingMissionId) {
    throw new Error('No onboardingMissionId specified')
  }

  const teamRef = doc(firestoreDb, COLLECTIONS.teams, teamId)

  await updateDoc(teamRef, { onboardingMissionId })

  if (usersIds?.length) {
    await getAxiosInstance().post('/admin/users-assign-content', {
      users: usersIds,
      onboardingMissionId: onboardingMissionId,
    })
  }
}

export const updateTeam = async team => {
  if (!team) throw new Error('No team specified')
  if (!team.id) {
    throw new Error('team must have an id')
  }

  const result = await doesTeamExistsFbFunction({ id: team.id })
  const { teamExists } = result.data

  if (teamExists) {
    const { id, ...data } = team
    const teamRef = doc(firestoreDb, COLLECTIONS.teams, id)

    return updateDoc(teamRef, data)
  } else {
    throw new Error('Team does not exist')
  }
}

export const archiveTeam = async teamId => {
  if (!teamId) throw new Error('No teamId specified')

  const result = await doesTeamExistsFbFunction({ id: teamId })

  const { teamExists } = result.data

  if (teamExists) {
    return archiveTeamFunction({ teamId })
  } else {
    throw new Error('Team does not exist')
  }
}

export const fetchTeamUsersPoints = async teamId => {
  const result = await retrieveTeamLeaderboards({
    teamId,
  })

  return result?.data
}
