import { useCallback, useMemo, useState, useEffect } from 'react'

import { DateTime } from 'luxon'

import { query, where } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { MCard, MEmptyContentPlaceholder } from '../molecules'
import { SquaresPlusIcon, FolderOpenIcon, Squares2X2Icon } from '@heroicons/react/24/outline'
import 'reactflow/dist/style.css'

import { userQuestionnairesCollectionRef, fetchAllContent } from '@edwin/sdk-admin'

export const OQuestionnairesGrid = ({ user }) => {
  const [activeQuestionnaire, setActiveQuestionnaire] = useState()
  const [missions, setMissions] = useState([])
  const [areMissionsLoading, setAreMissionsLoading] = useState(true)

  const userQuestionnairesCollectionQuery = useMemo(() => {
    return user?.id
      ? query(userQuestionnairesCollectionRef, where('userId', '==', user.id))
      : undefined
  }, [user?.id])
  const [questionnaires] = useCollectionData(userQuestionnairesCollectionQuery, {
    initialValue: [],
  })

  const questionnairesSortedByDate = useMemo(() => {
    return questionnaires.sort((a, b) => {
      return DateTime.fromISO(a.createdOn).toMillis() - DateTime.fromISO(b.createdOn).toMillis()
    })
  }, [questionnaires])

  const handleFetchContent = useCallback(async () => {
    setAreMissionsLoading(true)
    const content = await fetchAllContent()
    setMissions(content?.missions || [])
    setAreMissionsLoading(false)
  }, [])

  useEffect(() => {
    const asyncFn = async () => {
      await handleFetchContent()
    }

    asyncFn()
  }, [handleFetchContent])

  const groupedQuestions = useMemo(() => {
    let groups = {}
    if (!activeQuestionnaire) return groups

    Object.values(activeQuestionnaire.questionnaireAnswers).forEach(item => {
      let groupName = item.groupId
      let nestedGroupName

      if (groupName.includes('---')) {
        ;[groupName, nestedGroupName] = groupName.split(' --- ')

        if (!groups[groupName]) {
          groups[groupName] = { items: [], nestedGroups: {} }
        }

        if (!groups[groupName].nestedGroups[nestedGroupName]) {
          groups[groupName].nestedGroups[nestedGroupName] = []
        }

        groups[groupName].nestedGroups[nestedGroupName].push(item)
      } else {
        if (!groups[groupName]) {
          groups[groupName] = { items: [], nestedGroups: {} }
        }

        groups[groupName].items.push(item)
      }
    })

    // Sort items in each group and nested group by priority
    for (let group in groups) {
      groups[group].items.sort((a, b) => a.priority - b.priority)

      for (let nestedGroup in groups[group].nestedGroups) {
        groups[group].nestedGroups[nestedGroup].sort((a, b) => a.priority - b.priority)
      }
    }

    return groups
  }, [activeQuestionnaire])

  const getMissionNameById = useCallback(
    missionId => {
      return missions.find(({ id }) => id === missionId)?.title
    },
    [missions]
  )

  return (
    <div className="react-web--admin__container">
      {questionnaires.length ? (
        <div className="flex flex-1 min-h-full justify-between flex-wrap">
          <div className="w-96 pr-3">
            <ul className="grid grid-cols-1 gap-y-4">
              {questionnairesSortedByDate.map((questionnaire, index) => (
                <li key={index}>
                  <MCard
                    title="Questionnaire"
                    icon={<SquaresPlusIcon />}
                    onClick={() => {
                      setActiveQuestionnaire(questionnaire)
                    }}
                    className="mb-3"
                    isActive={questionnaire.createdOn === activeQuestionnaire?.createdOn}
                    items={[
                      {
                        label: 'Mission',
                        value:
                          getMissionNameById(questionnaire.missionId) || questionnaire.missionId,
                      },
                      {
                        label: 'Submitted on',
                        value: DateTime.fromISO(questionnaire.createdOn).toLocaleString(
                          DateTime.DATETIME_SHORT
                        ),
                      },
                    ]}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1 mt-8 ml-8 rounded-lg">
            {activeQuestionnaire && (
              <div>
                {Object.entries(groupedQuestions).map(([groupName, group], groupIndex) => (
                  <div className="mb-10 pb-10 border-b border-gray-100 last:border-0">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
                      <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                          {groupName}
                        </h2>
                        {/* <p className="mt-4 leading-7 text-gray-600">
                      Quam nunc nunc eu sed. Sed rhoncus quis ultricies ac pellentesque.
                    </p> */}
                      </div>
                      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                        {group.items.map((item, itemIndex) => (
                          <div className="rounded-2xl bg-gray-50 p-8 flex flex-col justify-between">
                            <h3 className="text-base font-semibold leading-7 text-gray-900">
                              {item.questionLabel}
                            </h3>
                            {item.answers.map((answer, answerIndex) => (
                              <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                                <div>
                                  <dt className="sr-only">Answer</dt>
                                  <dd>
                                    <span className="font-bold text-lg text-accent">
                                      {answer.label}
                                    </span>
                                  </dd>
                                </div>
                              </dl>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                    <>
                      {Object.entries(group.nestedGroups).map(
                        ([nestedGroupName, nestedGroup], nestedGroupIndex) => (
                          <div className="mt-8 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
                            <div>
                              <h2 className="ml-8 text-xl font-bold tracking-tight text-gray-900">
                                {groupName} &rarr; {nestedGroupName}
                              </h2>
                              {/* <p className="mt-4 leading-7 text-gray-600">
                      Quam nunc nunc eu sed. Sed rhoncus quis ultricies ac pellentesque.
                    </p> */}
                            </div>
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                              {nestedGroup.map((item, itemIndex) => (
                                <div className="rounded-2xl bg-gray-50 p-10">
                                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                                    {item.questionLabel}
                                  </h3>
                                  {item.answers.map((answer, answerIndex) => (
                                    <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                                      <div>
                                        <dt className="sr-only">Answer</dt>
                                        <dd>
                                          <span className="font-bold text-lg text-accent">
                                            {answer.label}
                                          </span>
                                        </dd>
                                      </div>
                                    </dl>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      )}
                    </>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <MEmptyContentPlaceholder description="No questionnaires to show" />
      )}
    </div>
  )
}
